import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Donor, DonorService } from 'src/app/funding/donor.service';
import { Organization } from '../organization.model';
import { OrganizationService } from '../organization.service';
import { PROJECT_STATUS, ProjectService } from '../project.service';
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef } from "@angular/material/dialog";

import { AuthenticationService } from "../../_services/authentication.service";


@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit {
  addProjectForm: FormGroup;
  organizations: Organization[];
  donorsList: Donor[];
  donors: Donor[];
  selectedOrganisations = [];
  status: string[] = PROJECT_STATUS;

  chosenDonors: string[] = [];
  donorCtrl = new FormControl();
  autocompleteFilteredDonors: Observable<Donor[]>;

  organizationsList: Organization[];
  chosenOrganizations: string[] = [];
  orgCtrl = new FormControl();
  autocompleteFilteredOrganizations: Observable<Organization[]>;

  currentUser;

  @ViewChild('donorInput') donorInput: ElementRef<HTMLInputElement>;
  @ViewChild('orgInput') orgInput: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private donorService: DonorService,
    private organizationService: OrganizationService,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<ProjectCreateComponent>,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {

    this.currentUser = this.authenticationService.currentUserValue;

    this.addProjectForm = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      short_name: ['', Validators.required],
      organizations_id: [[]],
      donors_id: [[]],
      date_start: [null, Validators.required],
      date_end: [null, Validators.required],
      contact_person: [this.currentUser.user.first_name + ' ' + this.currentUser.user.last_name, Validators.required],
      contact_email: [this.currentUser.user.email, Validators.required],
      status: [PROJECT_STATUS[0]],
      comments: ['']
    });

    this.organizationService.list().subscribe((orgainzationList) => {
      this.organizations = orgainzationList;
      this.organizationsList = [...orgainzationList];
      this.autocompleteFilteredOrganizations = this.orgCtrl.valueChanges
        .pipe(
          startWith(''),
          map(org => org ? this._filterOrganizations(org) : this.organizationsList.slice())
        );
    });

    this.donorService
      .list()
      .subscribe((data) => {
        this.donorsList = [...data];
        this.donors = data;
        this.autocompleteFilteredDonors = this.donorCtrl.valueChanges
          .pipe(
            startWith(''),
            map(donor => donor ? this._filterDonors(donor) : this.donorsList.slice())
          );
      });
  }

  onSubmit() {
    if (this.addProjectForm.valid) {
      const startDate = this.addProjectForm.get('date_start').value;
      this.addProjectForm.get('date_start').setValue(new DatePipe('en-US').transform(startDate, 'yyyy-MM-dd'))
      const endDate = this.addProjectForm.get('date_end').value;
      this.addProjectForm.get('date_end').setValue(new DatePipe('en-US').transform(endDate, 'yyyy-MM-dd'))
      this.projectService
        .create(this.addProjectForm.value)
        .subscribe((result) => {
          this.addProjectForm.patchValue(result);
          this.dialogRef.close(result);
        });
    }
  }

  remove(donor: string): void {
    const index = this.chosenDonors.indexOf(donor);
    if (index >= 0) {
      this.chosenDonors.splice(index, 1);
      this.addProjectForm.get('donors_id').value.splice(index, 1);
      const donorToAdd = this.donors.find(d => d.name === donor);
      this.donorsList.push(donorToAdd);
    }
  }

  removeOrg(organization: string): void {
    const index = this.chosenOrganizations.indexOf(organization);
    if (index >= 0) {
      this.chosenOrganizations.splice(index, 1);
      this.addProjectForm.get('organizations_id').value.splice(index, 1);
      const orgToAdd = this.organizations.find(org => org.name === organization);
      this.organizationsList.push(orgToAdd);
      this.orgInput.nativeElement.value = '';
      this.orgCtrl.setValue('');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.chosenDonors.indexOf(event.option.viewValue);
    if (index === -1) {
      this.chosenDonors.push(event.option.viewValue);
      this.chosenDonors.sort((a,b)=> a.localeCompare(b));
      const selectedDonor = this.donorsList.find(donor => donor.name === event.option.viewValue);
      this.addProjectForm.get('donors_id').value.push(selectedDonor.id);
      this.donorsList = this.donorsList.filter(d => d.name !== selectedDonor.name);
    }
    this.donorInput.nativeElement.value = '';
    this.donorCtrl.setValue('');
    this.donorCtrl.disable();
    this.donorCtrl.enable();
  }

  selectedOrg(event: MatAutocompleteSelectedEvent): void {
    const index = this.chosenOrganizations.indexOf(event.option.viewValue);
    if (index === -1) {
      this.chosenOrganizations.push(event.option.viewValue);
      this.chosenOrganizations.sort((a,b)=> a.localeCompare(b));
      const selectedOrg = this.organizationsList.find(org => org.name === event.option.viewValue);
      this.addProjectForm.get('organizations_id').value.push(selectedOrg.id);
      this.organizationsList = this.organizationsList.filter(org => org.name !== selectedOrg.name);
    }
    this.orgInput.nativeElement.value = '';
    this.orgCtrl.setValue('');
    this.orgCtrl.disable();
    this.orgCtrl.enable();
  }

  private _filterDonors(value: string): Donor[] {
    const filterValue = value.toLowerCase();
    return this.donorsList.filter(state => (state.name.toLowerCase().indexOf(filterValue) === 0));
  }
  private _filterOrganizations(value: string): Organization[] {
    const filterValue = value.toLowerCase();
    return this.organizationsList.filter(org => (org.short_name.toLowerCase().indexOf(filterValue) === 0));
  }
}
