<div class="header">
  <div class="header-container">
    <div>
      <a href="/?project={{activeProject?.id}}"><img src="/assets/images/logo.png" style="width:100px" /></a>
    </div>

    <div>
      <div style="display: flex; ">

        <div class="select-project header-button">
          <mat-select (selectionChange)="chooseProject($event)" [value]="activeProject?.id">
            <mat-option *ngFor="let project of projects" [value]="project.id" matTooltip={{project.name}}>
              {{ project.name }}
            </mat-option>
          </mat-select>
        </div>
        <button *ngIf="currentUser?.user.is_staff" mat-flat-button class="header-button" (click)="newProjectDialog()">
          Add Project
          <mat-icon svgIcon="plus" class="project-icon"></mat-icon>
        </button>
        <button *ngIf="canEdit && this.activeProject" mat-flat-button class="header-button"
          (click)="updateProject()">Edit Project
          <mat-icon svgIcon="olovka" class="project-icon"></mat-icon>
        </button>
        <button *ngIf="!canEdit && this.activeProject" mat-flat-button class="header-button"
          (click)="updateProject()">Project Info <mat-icon>info</mat-icon>
        </button>
        <button *ngIf="this.activeProject" mat-flat-button class="header-button"
          (click)="projectReport()">Project Report
          <mat-icon class="dafault-icon table-icon">table_chart</mat-icon>
        </button>
        <button *ngIf="currentUser?.user.is_staff && this.activeProject" mat-flat-button class="header-button"
          (click)="deleteProjectDialog(activeProject)">Delete Project <mat-icon svgIcon="kanta"></mat-icon></button>
        <button mat-flat-button (click)="redirectToAdmin()" class="header-button" title="Administration" *ngIf="currentUser?.user.is_superuser">
          <mat-icon class="defaul-icon">settings</mat-icon>
        </button>
        <button mat-flat-button (click)="logout()" class="header-button">
          <mat-icon class="defaul-icon">logout</mat-icon>
        </button>
      </div>
      <div style="margin-left:8px; margin-top:8px">
        <mat-checkbox (change)="showMyProjects($event.checked)" color="primary">
          Show Only My Projects
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>