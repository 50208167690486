<div style='max-height:70vh; overflow-y: overlay;'>

  <button mat-button color="primary" (click)="addNamevalue()" style="margin-top: 20px; font-weight: 500" *ngIf="canEdit">
    Add attribute +
  </button>

  <form [formGroup]="form">

    <mat-table [dataSource]="dataSource" formArrayName="namevaluelocations">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field style="width: 300px;">
            <mat-label></mat-label>
            <mat-select formControlName="namevalue" placeholder="Name">
              <mat-option *ngFor="let option of pairs" [value]="option.id" [disabled]='isDisabled(option)'>
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select formControlName="value" *ngIf="isSelect(row)==1">
              <mat-option *ngFor="let option of getSelectOptions(row)" [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-select>
            <input  matInput formControlName="value" *ngIf="isSelect(row)==2"/>
            <input  type='number' matInput formControlName="value" *ngIf="isSelect(row)==3"/>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <button type="button" mat-icon-button (click)="deleteDialog(row, i)" *ngIf="canEdit">
            <mat-icon svgIcon="kanta"></mat-icon>
          </button>
        </mat-cell>
        
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

     </mat-table>

  </form>

</div>
