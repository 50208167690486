<div [formGroup]="yearForm">
  <div class="sidenav-container">
    <div #sidenav class="sidenav">
      <div class="sidenav-menu">
        <button mat-raised-button color="primary" class="sidenav-button" (click)="addLocationDialog()" *ngIf="canEdit">
          <span>Manage Locations</span>
          <mat-icon svgIcon="plus"></mat-icon>
        </button>
        <div class="projects-list">
          <button mat-raised-button class="sidenav-button" *ngFor="let item of locations.value"
            [value]="item.location.id" (click)="locationService.changeLocation(item.location)"
            [style.background-color]=" item.location.id==currentLocation?.id ? '#3f51b5' : 'white'"
            [style.color]="item.location.id==currentLocation?.id ? 'white' : 'black'" title="{{item.location.name}}">
            <span style="text-overflow: ellipsis; overflow: hidden;
            white-space: nowrap;width:125px; display: inline-block;">{{ item.location.name }}</span>
            <mat-icon svgIcon="{{item.status}}"></mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div style="width:100%">

      <div *ngIf="locations.length==0;else other_content" class="no-locations">
        Select location...
      </div>

      <ng-template #other_content>
        <ng-container formArrayName="locations">
          <mat-tab-group #matLocationTabGroup [(selectedIndex)]="currentLocationTab" [class.header-less-tabs]="true" animationDuration="0ms">
            
            <mat-tab *ngFor="let locationControl of locations.controls; index as i; trackBy:trackByFn" [formGroupName]="i">
              <div style="display: flex; align-items: center;">
                <span class="location-name-title">{{ locationControl.value.location.name }}</span>
                <button mat-mini-fab color="primary" class="copy-button" (click)="copyLocationDialog()" matTooltip="Copy Location"
                  *ngIf="canEdit && locations.length">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </div>
              <div style="padding: 20px 0 0 20px;display:flex;">

                <mat-form-field appearance="outline" style="width:180px;float:left;">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" required>
                    <mat-option *ngFor="let option of interventionStatus" [value]="option.value">
                      <mat-icon svgIcon="{{option.value}}"></mat-icon>
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width:100%;">
                  <mat-label>Action</mat-label>
                  <input matInput placeholder="Action" formControlName="action" md-clear-button="true">
                </mat-form-field>

              </div>

              <div style="padding-left:20px;">
                <mat-form-field appearance="outline" style="width:100%">
                  <mat-label>Tags</mat-label>
                  <mat-chip-list #chipGrid aria-label="Tag selection">
                    <mat-chip color="primary" *ngFor="let tag of tags" (removed)="remove(tag)">
                      {{tag.name}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Select..." #tagInput [formControl]="tagCtrl" matInput
                    [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.id" [disabled]='!tag.is_active'>
                      {{tag.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>


              <mat-tab-group #matFuBeTabGroup [(selectedIndex)]="currentFBTab" [class.header-view-tabs]="true" animationDuration="0ms">
                <mat-tab label="Funding">
                  <app-funding [form]='locationControl' [canEdit]="canEdit" [yearForm]="yearForm"></app-funding>
                </mat-tab>
                <mat-tab label="Beneficiaries">
                  <app-beneficiaries [form]='locationControl' [canEdit]="canEdit"></app-beneficiaries>
                </mat-tab>
                <mat-tab label="Attributes">
                  <app-namevalue [form]='locationControl' [canEdit]="canEdit" [yearForm]="yearForm"></app-namevalue>
                </mat-tab>
              </mat-tab-group>
            </mat-tab>
          </mat-tab-group>

        </ng-container>
      </ng-template>

    </div>
  </div>
</div>