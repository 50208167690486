import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Organization } from '../organization.model';
import { OrganizationService } from '../organization.service';

import { Project } from '../project.model';
import { PROJECT_STATUS, ProjectService } from '../project.service';

import { Donor, DonorService } from '../../funding/donor.service';

import { InterventionService } from '../../intervention/intervention.service';
import { FundingService } from '../../funding/funding.service';
import { AuthenticationService } from '../../_services/authentication.service';


@Component({
  selector: 'app-project-update',
  templateUrl: './project-update.component.html',
  styleUrls: ['./project-update.component.scss']
})
export class ProjectUpdateComponent implements OnInit {
  project: Project;
  addProjectForm: FormGroup;
  organizations: Organization[];
  donorsList: Donor[];
  donors: Donor[];
  selectedOrganisations = [];

  chosenDonors: string[] = [];
  donorCtrl = new FormControl();
  autocompleteFilteredDonors: Observable<Donor[]>;

  organizationsList: Organization[];
  chosenOrganizations: string[] = [];
  orgCtrl = new FormControl();
  autocompleteFilteredOrganizations: Observable<Organization[]>;

  yearStart: number;
  yearEnd: number;
  status: string[] = PROJECT_STATUS;

  currentUser;
  canEdit = false;

  @ViewChild('donorInput') donorInput: ElementRef<HTMLInputElement>;
  @ViewChild('orgInput') orgInput: ElementRef<HTMLInputElement>;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private donorService: DonorService,
    private organizationService: OrganizationService,
    private projectService: ProjectService,
    private interventionService: InterventionService,
    private fundingService: FundingService,
    private dialogRef: MatDialogRef<ProjectUpdateComponent>,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    this.projectService.currentProject.subscribe(project => {
      this.project = project;
      this.yearStart = new Date(this.project.date_start).getFullYear();
      this.yearEnd = new Date(this.project.date_end).getFullYear();
      this.canEdit = this.currentUser.user.is_superuser || (this.currentUser.user.projects.find(p => p=== project.id)!=undefined);
      const disabled = !this.canEdit;
      this.addProjectForm = this.formBuilder.group({
        id: [this.project.id],
        name: [{ value: this.project.name, disabled }, Validators.required],
        short_name: [{ value: this.project.short_name, disabled }, Validators.required],
        organizations_id: [[]],
        donors_id: [[]],
        date_start: [{ value: this.project.date_start, disabled }, Validators.required],
        date_end: [{ value: this.project.date_end, disabled }, Validators.required],
        contact_person: [{ value: this.project.contact_person, disabled }, Validators.required],
        contact_email: [{ value: this.project.contact_email, disabled }, Validators.required],
        status: [{ value: this.project.status, disabled }],
        comments: [{ value: this.project.comments, disabled }]
      });

      this.organizationService.list().subscribe((orgainzationList) => {
        this.organizations = orgainzationList;
        this.chosenOrganizations = this.project.organizations.map((org) => org.name);
        this.organizationsList = orgainzationList.filter((org) => !this.chosenOrganizations.includes(org.name));
        this.addProjectForm.get('organizations_id').setValue(this.project.organizations.map((org) => org.id));
        this.autocompleteFilteredOrganizations = this.orgCtrl.valueChanges
          .pipe(
            startWith(''),
            map(org => org ? this._filterOrganizations(org) : this.organizationsList.slice())
          );
      });

      this.donorService
        .list()
        .subscribe((data) => {
          this.donors = data;
          this.chosenDonors = this.project.donors.map((donor) => donor.name);
          this.donorsList = data.filter((d) => !this.chosenDonors.includes(d.name));
          this.addProjectForm.get('donors_id').setValue(this.project.donors.map((donor) => donor.id));
          this.autocompleteFilteredDonors = this.donorCtrl.valueChanges
            .pipe(
              startWith(''),
              map(donor => donor ? this._filterDonors(donor) : this.donorsList.slice())
            );
        });
    });
  }

  // async checkExistingInterventions(years: number[]): Promise<boolean> {
  //   let retValue = true;
  //   const interventions = await this.interventionService.getByProjectID(this.project.id).toPromise();
  //   for (const intervention of interventions) {
  //     for (const year of years) {
  //       const fundings = await this.fundingService.getByInterventionYear(intervention.id, year).toPromise();
  //       if (fundings.length) {
  //         retValue = false;
  //         return;
  //       }
  //     }
  //   }
  //   return retValue;
  // }

  async onSubmit() {
    if (this.addProjectForm.valid) {
      const startDate = this.addProjectForm.get('date_start').value;
      this.addProjectForm.get('date_start').setValue(new DatePipe('en-US').transform(startDate, 'yyyy-MM-dd'));
      const newYearStart = new Date(startDate).getFullYear();
      const endDate = this.addProjectForm.get('date_end').value;
      this.addProjectForm.get('date_end').setValue(new DatePipe('en-US').transform(endDate, 'yyyy-MM-dd'));
      const newYearEnd = new Date(endDate).getFullYear();
      const years: number[] = [];
      if (newYearEnd < this.yearEnd) {
        let year;
        for (year = newYearEnd + 1; year <= this.yearEnd; year++) {
          years.push(year);
        }
      }
      if (this.yearStart < newYearStart) {
        let year;
        for (year = this.yearStart; year < newYearStart; year++) {
          years.push(year);
        }
      }
      let update = true;
      // if (years.length) update = await this.checkExistingInterventions(years);
      if (update) {
        this.projectService
          .update(this.addProjectForm.value)
          .subscribe((result) => {
            const shortNameChanged = this.project.short_name !== result.short_name;
            this.addProjectForm.patchValue(result);
            this.projectService.changeProject(result);
            this.snackBar.open('Project successfully updated', 'update', { duration: 2000 });
            this.dialogRef.close(shortNameChanged);
          });
      }
      else {
        this.snackBar.open('Remove existing fundings and beneficiaries before removing year for project', 'reject', { duration: 4000 });
      }
    }
  }

  remove(donor: string): void {
    this.interventionService.getByProjectID(this.project.id).subscribe(async (interventions) => {
      for (const intervention of interventions) {
        // const fundings = await this.fundingService.getByIntervention(intervention.id).toPromise();
        // for (const funding of fundings) {
        //   if (funding.donor === this.donors.find(d => d.name === donor).id) {
        //     this.snackBar.open('Can not remove donor,if funding exists', 'reject', { duration: 2000 });
        //     return;
        //   }
        // }
      }
      const index = this.chosenDonors.indexOf(donor);
      if (index >= 0) {
        this.chosenDonors.splice(index, 1);
        this.addProjectForm.get('donors_id').value.splice(index, 1);
        const donorToAdd = this.donors.find(d => d.name === donor);
        this.donorsList.push(donorToAdd);
      }
    });
  }

  removeOrg(organization: string): void {
    const index = this.chosenOrganizations.indexOf(organization);
    if (index >= 0) {
      this.chosenOrganizations.splice(index, 1);
      this.addProjectForm.get('organizations_id').value.splice(index, 1);
      const orgToAdd = this.organizations.find(org => org.name === organization);
      this.organizationsList.push(orgToAdd);
      this.orgInput.nativeElement.value = '';
      this.orgCtrl.setValue('');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.chosenDonors.indexOf(event.option.viewValue);
    if (index === -1) {
      this.chosenDonors.push(event.option.viewValue);
      this.chosenDonors.sort((a,b)=> a.localeCompare(b));
      const selectedDonor = this.donorsList.find(donor => donor.name === event.option.viewValue);
      this.addProjectForm.get('donors_id').value.push(selectedDonor.id);
      this.donorsList = this.donorsList.filter(d => d.name !== selectedDonor.name);
    }
    this.donorInput.nativeElement.value = '';
    this.donorCtrl.setValue('');
    this.donorCtrl.disable();
    this.donorCtrl.enable();
  }

  selectedOrg(event: MatAutocompleteSelectedEvent): void {
    const index = this.chosenOrganizations.indexOf(event.option.viewValue);
    if (index === -1) {
      this.chosenOrganizations.push(event.option.viewValue);
      this.chosenOrganizations.sort((a,b)=> a.localeCompare(b));
      const selectedOrg = this.organizationsList.find(org => org.name === event.option.viewValue);
      this.addProjectForm.get('organizations_id').value.push(selectedOrg.id);
      this.organizationsList = this.organizationsList.filter(org => org.name !== selectedOrg.name);
    }
    this.orgInput.nativeElement.value = '';
    this.orgCtrl.setValue('');
    this.orgCtrl.disable();
    this.orgCtrl.enable();
  }

  private _filterDonors(value: string): Donor[] {
    const filterValue = value.toLowerCase();
    return this.donorsList.filter(state => (state.name.toLowerCase().indexOf(filterValue) === 0));
  }

  private _filterOrganizations(value: string): Organization[] {
    const filterValue = value.toLowerCase();
    return this.organizationsList.filter(org => (org.name.toLowerCase().indexOf(filterValue) === 0));
  }
}

