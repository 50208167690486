

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Organization } from './organization.model';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  baseUrl = '/api/organizations/';

  constructor(private http: HttpClient) { }

  list(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.baseUrl);
  }

}
