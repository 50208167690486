<h2 mat-dialog-title>Copy Location</h2>
<mat-dialog-content style="max-width: 500px;">
  <div style="margin-top: 8px;margin-bottom: 16px;">
    <mat-radio-group aria-label="Where To Copy" (change)="selectCopyTo($event)" color="primary">
      <mat-radio-button value="0" checked >Copy To Another Location</mat-radio-button>
      <mat-radio-button value="1">Copy To Another Year</mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="copyTo<1">
    <div>
      <mat-label style="font-size:small; opacity:'0.7'">Copy year to</mat-label>
      <div class="select-year">
        <mat-select (selectionChange)="changeLocation($event)">
          <mat-option *ngFor="let location of locations index as i" [value]="location" [disabled]="location.id==currentLocation">
            {{ location.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div *ngIf="copyTo>0">
    <div>
      <mat-label style="font-size:small; opacity:'0.7'">Select Year</mat-label>
      <div class="select-year">
        <mat-select (selectionChange)="changeYear($event)">
          <mat-option *ngFor="let control of years.value index as i" [value]="i" [disabled]="currentYear==i">
            {{ control.year}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div>
      <mat-label style="font-size:small; opacity:'0.7'">Copy year to</mat-label>
      <div class="select-year">
        <mat-select (selectionChange)="changeLocation($event)">
          <mat-option *ngFor="let location of locations index as i" [value]="location">
            {{ location.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div style="margin-bottom: 16px;">
    <mat-checkbox (change)="overwriteData($event.checked)" color="primary">
      Overwrite
    </mat-checkbox>
    <div style="color:red" *ngIf="overwrite">
      <i>
        If data is selected to be overwritten, location and all underlying related data 
        (funding, beneficiaries) that exist are replaced.
      </i>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)='copyIntervention()'>Copy</button>
</mat-dialog-actions>
