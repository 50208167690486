import { Component, Inject } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FundingService } from '../funding.service';


@Component({
  selector: 'app-funding-delete',
  templateUrl: './funding-delete.component.html',
  styleUrls: ['./funding-delete.component.scss']
})
export class FundingDeleteComponent {

  funding;

  constructor(
    private snackBar: MatSnackBar,
    private fundingService: FundingService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.funding = data;
  }

  deleteFunding() {
    this.fundingService.delete(this.funding.value.id).subscribe((r) => {
      this.snackBar.open('Donor deleted successfully', 'delete', { duration: 2000 });
    });

  }
}
