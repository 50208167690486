import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProjectCreateComponent } from "./project-create/project-create.component";
import { ProjectUpdateComponent } from './project-update/project-update.component';
import { ProjectDeleteComponent } from './project-delete/project-delete.component';
import { ProjectReportComponent } from './project-report/project-report.component';
import { ProjectReportDetailsComponent } from './project-report-details/project-report-details.component';
import { HeaderModule } from "../header/header.module";
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    ProjectCreateComponent,
    ProjectUpdateComponent,
    ProjectDeleteComponent,
    ProjectReportComponent,
    ProjectReportDetailsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    HeaderModule,
    MatTableModule
  ],
  exports: [
    ProjectCreateComponent,
    ProjectUpdateComponent,
    ProjectDeleteComponent,
    ProjectReportComponent,
    ProjectReportDetailsComponent
  ]
})
export class ProjectModule { }
