import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { LocationType } from 'src/app/intervention/intervention.model';
import { LocationFlatNode } from '../locations.model';
import { LocationService } from '../locations.service';

@Component({
  selector: 'app-location-copy',
  templateUrl: './location-copy.component.html',
  styleUrls: ['./location-copy.component.scss']
})
export class LocationCopyComponent {

  locations: LocationFlatNode[]
  currentLocation: number;
  currentIndex: number
  currentYear: number;
  years: FormGroup;
  copyTo = 0;
  selectedLocation: LocationType;
  selectedYear: number;
  overwrite = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<LocationCopyComponent>,
    public locationService: LocationService
  ) {
    this.years = data.years
    this.currentYear = data.year
    this.currentIndex = data.index;
    this.selectedYear = this.currentYear;
    this.currentLocation = data.currentLocation;
  }

  ngOnInit(){
    this.locationService.listFlat().subscribe((data) => {
      this.locations = data;
      this.locations.sort((a,b)=> a.name.localeCompare(b.name));
    });
  }

  selectCopyTo(event: MatRadioChange) {
    this.copyTo = event.value;
  }

  changeLocation(event: MatSelectChange) {
    this.selectedLocation = event.value
  }

  changeYear(event: MatSelectChange) {
    this.selectedYear = event.value
  }

  overwriteData(selected: any): void {
    this.overwrite = selected;
  }

  async copyIntervention(): Promise<void> {
      this.dialogRef.close({selectedYear: this.selectedYear, selectedLocation: this.selectedLocation, overwrite: this.overwrite, index: this.currentIndex, currentYear:this.currentYear})
  }
}
