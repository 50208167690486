import { Component, Inject, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Project } from '../project/project.model';
import { ProjectService } from '../project/project.service';
import { ProjectUpdateComponent } from "../project/project-update/project-update.component";
import { ProjectDeleteComponent } from "../project/project-delete/project-delete.component";

import { UserToken } from '../user/user.model';
import { AuthenticationService } from "../_services/authentication.service";

import { LocationService } from '../locations/locations.service';
import { InterventionService } from '../intervention/intervention.service';
import { MatSelectChange } from '@angular/material/select';
import { ProjectCreateComponent } from '../project/project-create/project-create.component';

import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  activeProject: Project;
  currentUser: UserToken;
  projects: Project[];
  canEdit = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private authenticationService: AuthenticationService,
    private locationService: LocationService,
    private interventionService: InterventionService,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    this.projectService.list().subscribe(projects => {
      this.projects = projects.sort((a,b)=> a.name.localeCompare(b.name));
      // if(!this.activeProject) {
      //   this.projectService.changeProject(this.projects[0])
      // }
    });

    this.projectService.currentProject.subscribe(project => {
      if (project) {
        this.activeProject = project;
        this.canEdit = this.currentUser.user.is_superuser || (this.currentUser.user.projects.find(p => p=== project.id)!=undefined)
      }
    });

  }

  updateProject(): void {
    const dialogRef = this.dialog.open(ProjectUpdateComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectService.list().subscribe(projects => {
          this.projectService.changeProject(projects[projects.findIndex(p => p.id === this.activeProject.id)]);
        });
      }
    });
  }

  logout(): void {
    this.projectService.changeProject(null);
    this.authenticationService.logout();
  }

  onProjectClicked(): void {
    this.locationService.changeLocation(null);
    this.router.navigate(['/'], { queryParams: { project: this.activeProject.id } });
  }

  deleteProjectDialog(): void {

    this.interventionService.getByProjectID(this.activeProject.id).subscribe(data => {
      if (data.length) {
        this.snackBar.open('You have ' + data.length + ' intervention/s. First delete them and than you can delete project.', 'Cancel', {
          duration: 6000,
        });
        return;
      } else {
        const dialogRef = this.dialog.open(ProjectDeleteComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.projectService.list().subscribe(projects => {
              if (projects.length) this.projectService.changeProject(projects[0]);
              else this.projectService.changeProject(null);
              this.projects = projects.sort((a,b)=> a.name.localeCompare(b.name));
            });
          }
        });
      }
    });


  }

  chooseProject(event: MatSelectChange): void {
    const newProject = this.projects.findIndex( p => p.id===event.value);
    this.projectService.changeProject(this.projects[newProject])
    this.router.navigate(['/'], { queryParams: { project: event.value } });
  }

  newProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectCreateComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectService.list().subscribe(projects => {
          const newProject = projects[projects.findIndex(p => p.id === result.id)]
          this.activeProject = newProject;
          this.projects = projects.sort((a,b)=> a.name.localeCompare(b.name));
          this.projectService.changeProject(newProject);
        });
      }
    });
  }

  redirectToAdmin(): void {
    this.document.location.href = '/admin';
}
 
  showMyProjects(selected: any): void {
    if(selected){
      this.projectService.myList().subscribe(projects => {
        this.projects = projects.sort((a,b)=> a.name.localeCompare(b.name));
        this.router.navigate(['/'], { queryParams: { project: this.projects[0].id } });
      });
    } else {
      this.projectService.list().subscribe(projects => {
        this.projects = projects.sort((a,b)=> a.name.localeCompare(b.name));
        this.router.navigate(['/'], { queryParams: { project: this.projects[0].id } });
      });
    }
   

  }

  projectReport(): void {
    this.router.navigate(['projectreport/'+this.activeProject.id],  { queryParams: { project: this.activeProject.id } });
  }

}
