<h3 mat-dialog-title>Select locations</h3>
<div mat-dialog-content>

  <div style="width: 500px;">

    <div class="autocompleteInput">
      <input placeholder="Locations..." #locationInput [formControl]="locationCtrl" matInput
          [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of autocompleteFilteredLocations | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    
    <div class="selectedTitle">Selected locations</div>
    <div style="max-height: 300px;overflow-y: auto;">
      <ul>
        <li *ngFor="let item of locationSelected" class="selectedLocation">
            <div>
              {{ item.name }}
            </div>
            <button mat-icon-button (click)="userLocationRemove(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </li>
      </ul>
    </div>

  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="onSubmit()">Done</button>
</div>
