import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Project } from './project.model';
import { ActivatedRoute, Router } from "@angular/router";

export const PROJECT_STATUS: string[] = ['planned', 'ongoing', 'completed'];

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseUrl = '/api/projects/';

  private projectSource = new BehaviorSubject(null);
  currentProject = this.projectSource.asObservable();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.project) {
        if (this.projectSource.getValue()?.id !== parseInt(params.project, 10)) {
          this.getByID(parseInt(params.project, 10)).subscribe(project => {
            this.changeProject(project);
          });
        }
      }
      else {
        this.list().subscribe(projects => {
          const sortedProjects = projects.sort((a,b)=> a.name.localeCompare(b.name));
          this.changeProject(sortedProjects[0]);
        });
      }
    });
  }

  list(): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl);
  }

  myList(): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl+'myprojects/');
  }

  getByID(projectID: number): Observable<Project> {
    return this.http.get<Project>(this.baseUrl + projectID + '/');
  }

  create(project: Project): Observable<Project> {
    return this.http.post<Project>(this.baseUrl, project);
  }

  update(project: Project): Observable<Project> {
    return this.http.patch<Project>(this.baseUrl + project.id + '/', project);
  }

  delete(projectId: number) {
    return this.http.delete(this.baseUrl + projectId + '/');
  }

  changeProject(projectData: Project) {
    this.projectSource.next(projectData);
  }

  reportByType(projectId: number, type: string) {
    return this.http.get(`/api/${type}/` + projectId + '/');
  }

  exportReportByLocatioin(project: number, location:string) {
    return this.http.get(`/api/export-bylocation/${project}/?location=`+encodeURIComponent(location), {headers:{ 'Content-Type': 'application/json' }, responseType: 'blob' as 'json'});
  }

  exportReportByDonor(project: number, donor:string) {
    return this.http.get(`/api/export-bydonor/${project}/?donor=`+encodeURIComponent(donor), {headers:{ 'Content-Type': 'application/json' }, responseType: 'blob' as 'json'});
  }
}
