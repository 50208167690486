<div class="content">
  <app-header></app-header>
  <div class="actions">
    <button mat-icon-button (click)="exitDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="radioButton">
    <mat-radio-group aria-label="Select an option" (change)="reportBy($event)" color="primary">
      <mat-radio-button value="reportintbylocation" checked>Report By Location</mat-radio-button>
      <mat-radio-button value="reportintbydonor">Report By Donor</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="wrapTable" *ngIf="locations.length">

    <table class="typeLD  tableLocations">
      <tr>
        <th colspan="2">
          Years
        </th>
      </tr>
      <tr>
        <th>
          {{reportType==='reportbylocation' ? "Location" : "Donor"}}
        </th>
        <th>
          {{reportType==='reportbylocation' ? "Donor" : "Location"}}
        </th>
      </tr>
      <tr *ngFor="let location of locations">
        <td>
          {{location}}
        </td>
        <td style="padding: 2px; align-items: center;">
          <div class="details" (click)="details(location)">
            Details
          </div>
        </td>
      </tr>
    </table>

    <table class="typeLD tableYear" *ngFor="let year of dataKeys(report)">
      <tr>
        <th colspan="3">
          {{year}}
        </th>
      </tr>
      <tr>
        <th>Through UNDP</th>
        <th>Parallel</th>
        <th>Total</th>
      </tr>

      <tr *ngFor="let location of locations">
        <td>
          {{getFundingSum(location,year,'Through UNDP') | number:'1.0-0'}}
        </td>
        <td>
          {{getFundingSum(location,year,'Parallel') | number:'1.0-0'}}
        </td>
        <td>
          {{(getFundingSum(location,year,'Through UNDP') + getFundingSum(location,year,'Parallel')) |
          number:'1.0-0'}}
        </td>
      </tr>
    </table>

    <table class="typeLD tableYear">
      <tr>
        <th colspan="3">
          All Years
        </th>
      </tr>
      <tr>
        <th>Through UNDP</th>
        <th>Parallel</th>
        <th>Total</th>
      </tr>

      <tr *ngFor="let location of locations">
        <td>
          {{(sum[location]['Through UNDP'] || 0) | number:'1.0-0'}}
        </td>
        <td>
          {{(sum[location]['Parallel'] || 0) | number:'1.0-0'}}
        </td>
        <td>
          {{((sum[location]['Through UNDP'] || 0)+(sum[location]['Parallel'] || 0)) | number:'1.0-0'}}
        </td>
      </tr>
    </table>

  </div>
    <div class="no-table" *ngIf="report && locations.length===0">
      No fundings for this intervention
    </div>

</div>