import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Intervention, InterventionType, InterventionSpecificType, InterventionYearLocation, Tag } from './intervention.model';
@Injectable({
  providedIn: 'root'
})
export class TagService {

  baseUrl = '/api/tags/';

  constructor(private http: HttpClient) { }

  list(type:string): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.baseUrl+'?type='+type);
  }

}

@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  baseUrl = '/api/interventions/';

  constructor(
    private http: HttpClient,
  ) { }

  list(): Observable<Intervention[]> {
    return this.http.get<Intervention[]>(this.baseUrl);
  }

  getByProjectID(projectId: number): Observable<Intervention[]> {
    return this.http.get<Intervention[]>(this.baseUrl + '?project=' + projectId);
  }

  retrive(interventionId: number): Observable<Intervention> {
    return this.http.get<Intervention>(this.baseUrl + interventionId + '/');
  }

  create(interventionData: Intervention): Observable<Intervention> {
    return this.http.post<Intervention>(this.baseUrl, interventionData);
  }

  update(interventionData: Intervention): Observable<Intervention> {
    return this.http.patch<Intervention>(this.baseUrl + interventionData.id + '/', interventionData);
  }

  delete(interventionId: number) {
    return this.http.delete(this.baseUrl + interventionId + '/');
  }

  reportByType(projectId: number, type: string) {
    return this.http.get(`/api/${type}/` + projectId + '/');
  }

  missingYears(id: number){
    return this.http.post(this.baseUrl+id+'/missingyears/', {id})
  }

  exportReportByLocatioin(intervention: number, location:string) {
    return this.http.get(`/api/export-intervention-bylocation/${intervention}/?location=`+encodeURIComponent(location), {headers:{ 'Content-Type': 'application/json' }, responseType: 'blob' as 'json'});
  }

  exportReportByDonor(intervention: number, donor:string) {
    return this.http.get(`/api/export-intervention-bydonor/${intervention}/?donor=`+encodeURIComponent(donor), {headers:{ 'Content-Type': 'application/json' }, responseType: 'blob' as 'json'});
  }
}

@Injectable({
  providedIn: 'root'
})
export class InterventionTypeService {

  baseUrl = '/api/interventions-type/';

  constructor(private http: HttpClient) { }

  list(): Observable<InterventionType[]> {
    return this.http.get<InterventionType[]>(this.baseUrl);
  }

}

@Injectable({
  providedIn: 'root'
})
export class InterventionSpecificTypeService {

  baseUrl = '/api/interventions-spec-type/';

  constructor(private http: HttpClient) { }

  list(): Observable<InterventionSpecificType[]> {
    return this.http.get<InterventionSpecificType[]>(this.baseUrl);
  }

  retrive(specTypeId: number): Observable<InterventionSpecificType> {
    return this.http.get<InterventionSpecificType>(this.baseUrl + specTypeId + '/');
  }

}

@Injectable({
  providedIn: 'root'
})
export class YearLocationService {

  baseUrl = '/api/year-locations/';

  constructor(
    private http: HttpClient,
  ) { }

  list(): Observable<InterventionYearLocation[]> {
    return this.http.get<InterventionYearLocation[]>(this.baseUrl);
  }

  retrive(interYearLocId: number): Observable<InterventionYearLocation> {
    return this.http.get<InterventionYearLocation>(this.baseUrl + interYearLocId + '/');
  }

  create(interYearLocData: InterventionYearLocation): Observable<InterventionYearLocation> {
    return this.http.post<InterventionYearLocation>(this.baseUrl, interYearLocData);
  }

  update(interYearLocData: InterventionYearLocation): Observable<InterventionYearLocation> {
    return this.http.patch<InterventionYearLocation>(this.baseUrl + interYearLocData.id + '/', interYearLocData);
  }

  delete(interYearLocId: number) {
    return this.http.delete(this.baseUrl + interYearLocId + '/');
  }

}
