<div style="width:90vw !;">
  <div style="display: flex; align-items: baseline;justify-content: space-between;">
    <h2 mat-dialog-title>Details {{donor}}</h2>
    <button mat-raised-button color='primary' (click)="exportReport()">Download Excel Table</button>
  </div>

<mat-dialog-content class="mat-typography">
  <div class="wrapTable">

    <table class="typeLD  tableLocations total">
      <tr>
        <th>
          Years
        </th>
      </tr>
      <tr>
        <th>
          {{reportType==='reportbylocation' ? "Location" : "Donor"}}
        </th>
      </tr>
      <tr *ngFor="let loc of locations">
        <td>
          {{loc}}
        </td>
      </tr>
    </table>
  
    <table class="typeLD tableYear total" *ngFor="let year of dataKeys(report)">
      <tr>
        <th colspan="3">
          {{year}}
        </th>
      </tr>
      <tr>
        <th>Through UNDP</th>
        <th>Parallel</th>
        <th>Total</th>
      </tr>
  
      <tr *ngFor="let loc of locations" >
        <td>
          {{getFundingSum(loc,year,'Through UNDP') | number:'1.0-0'}}
        </td>
        <td>
          {{getFundingSum(loc,year,'Parallel') | number:'1.0-0'}}
        </td>
        <td>
          {{(getFundingSum(loc,year,'Through UNDP') + getFundingSum(loc,year,'Parallel')) |
          number:'1.0-0'}}
        </td>
      </tr>
    </table>
  
    <table class="typeLD tableYear total">
      <tr>
        <th colspan="3">
          All Years
        </th>
      </tr>
      <tr>
        <th>Through UNDP</th>
        <th>Parallel</th>
        <th>Total</th>
      </tr>
  
      <tr *ngFor="let loc of locations">
        <td>
          {{(sum[loc]['Through UNDP'] || 0) | number:'1.0-0'}}
        </td>
        <td>
          {{(sum[loc]['Parallel'] || 0) | number:'1.0-0'}}
        </td>
        <td>
          {{((sum[loc]['Through UNDP'] || 0)+(sum[loc]['Parallel'] || 0)) | number:'1.0-0'}}
        </td>
      </tr>
    </table>
  
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
</div>