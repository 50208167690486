import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import { LocationsModule } from '../locations/locations.module';
import { FundingModule } from '../funding/funding.module';
import { BeneficiariesModule } from '../beneficiaries/beneficiaries.module';
import { ProjectModule } from '../project/project.module';
import { InterventionReportDetailsComponent } from './intervention-report-details/intervention-report-details.component'
import { InterventionListComponent } from './intervention-list/intervention-list.component';
import { InterventionDeleteComponent } from './intervention-delete/intervention-delete.component';
import { InterventionCopyComponent } from './intervention-copy/intervention-copy.component';
import { InterventionYearCopyComponent } from './intervention-year-copy/intervention-year-copy.component';
import { InterventionDetailsComponent } from "./intervention-details/intervention-details.component";
import { InterventionReportComponent } from './intervention-report/intervention-report.component'
import { FundingBeneficiariesModule } from '../funding-beneficiaries/funding-beneficiaries.module';
import { HeaderModule } from '../header/header.module';
import { SaveChangesComponent } from './save-changes/save-changes.component';


@NgModule({
  declarations: [
    InterventionListComponent,
    InterventionDeleteComponent,
    InterventionDetailsComponent,
    SaveChangesComponent,
    InterventionCopyComponent,
    InterventionYearCopyComponent,
    InterventionReportComponent,
    InterventionReportDetailsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatStepperModule,
    MatSidenavModule,
    MatTabsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCheckboxModule,
    LocationsModule,
    FundingModule,
    BeneficiariesModule,
    ProjectModule,
    FundingBeneficiariesModule,
    HeaderModule,
  ],
})
export class InterventionsModule { }
