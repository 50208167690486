<h2 mat-dialog-title>Add Funding</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="fundingForm" style="display:flex; flex-direction:column">
      <mat-form-field appearance="outline" style="width:300px; margin-top: 8px;">
        <mat-label>Donor</mat-label>
        <mat-select formControlName="donor">
          <mat-option *ngFor="let option of activeProject.donors" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Type of funding</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let option of TYPES" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Amount</mat-label>
        <input type="number" matInput formControlName="sum" placeholder="0" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Locations</mat-label>
        <mat-select formControlName="locations" multiple>
          <mat-option #allSelected (click)="toggleAllSelection()" [value]="-1">All</mat-option>
          <mat-option *ngFor="let location of locations.value" [value]="location.location.id" (click)="tosslePerOne(allSelected.viewValue)">
            {{location.location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)='addFunding()' [disabled]="!fundingForm.valid">Add</button>
</mat-dialog-actions>
