import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocationFlatNode, LocationNode } from './locations.model';


@Injectable({
  providedIn: 'root'
})
export class LocationService {

  baseUrl = '/api/locations/';
  private locationSource = new BehaviorSubject(null);
  currentLocation = this.locationSource.asObservable();

  constructor(private http: HttpClient) {
    this.list();
  }

  retrive(id: number): Observable<LocationNode> {
    return this.http.get<LocationNode>(this.baseUrl + id);
  }

  list(): Observable<LocationNode[]> {
    return this.http.get<LocationNode[]>(this.baseUrl);
  }

  listFlat(): Observable<LocationFlatNode[]> {
    return this.http.get<LocationFlatNode[]>('/api/locationsflat/');
  }

  changeLocation(location): void {
    this.locationSource.next(location);
  }

}
