import { Component } from '@angular/core';
import { MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-save-changes',
  templateUrl: './save-changes.component.html',
  styleUrls: ['./save-changes.component.scss']
})
export class SaveChangesComponent {

  constructor(
    private dialogRef: MatDialogRef<SaveChangesComponent>,
  ) { }

  saveChanges(): any {
    this.dialogRef.close(true);
  }

  close(): any {
    this.dialogRef.close(false);
  }

}
