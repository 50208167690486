import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Beneficiary, BeneficiaryCategoryType } from './beneficiaries.model';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryCategoryService {

  baseUrl = '/api/beneficiary-category/';

  constructor(private http: HttpClient) { }

  list(): Observable<BeneficiaryCategoryType[]> {
    return this.http.get<BeneficiaryCategoryType[]>(this.baseUrl);
  }

}

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  baseUrl = '/api/beneficiaries/';

  constructor(private http: HttpClient) { }

  list(): Observable<Beneficiary[]> {
    return this.http.get<Beneficiary[]>(this.baseUrl);
  }

  getByIdInterventionYearLocation(interventionId: number): Observable<Beneficiary[]> {
    return this.http.get<Beneficiary[]>(this.baseUrl + '?intervention_year_location=' + interventionId);
  }

  create(beneficiary: Beneficiary): Observable<Beneficiary> {

    return this.http.post<Beneficiary>(this.baseUrl, beneficiary);
  }

  update(beneficiary: Beneficiary): Observable<Beneficiary> {
    return this.http.patch<Beneficiary>(this.baseUrl + beneficiary.id + '/', beneficiary);
  }

  delete(beneficiaryID: number) {
    return this.http.delete(this.baseUrl + beneficiaryID + '/');
  }
}


