import { InterventionYearLocation } from '../intervention/intervention.model';

export const FUNDING_TYPES: string[] = ['Through UNDP', 'Parallel', 'In-kind'];

export class Funding {
  id: number;
  donor: number;
  intervention_year_location: InterventionYearLocation | number;
  sum: number;
  year: number;
  type: string;

  constructor(funding?: Funding) {
    this.id = funding?.id;
    this.donor = funding?.donor;
    this.intervention_year_location = funding?.intervention_year_location;
    this.sum = funding?.sum;
    this.year = funding?.year;
    this.type = funding?.type;
  }
}
