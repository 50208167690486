import { FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { Intervention, InterventionYear, InterventionYearLocation } from './intervention.model';

import { Funding } from '../funding/funding.model';
import { FundingForm } from '../funding/funding.form';

import { Beneficiary } from '../beneficiaries/beneficiaries.model';
import { BeneficiaryForm } from '../beneficiaries/beneficiaries.form';
import { NamevalueForm } from '../namevalue/namevalue.form';
import { Namevalue } from '../namevalue/namevalue.model';


export class YearLocationForm {
  id = new FormControl()
  intervention_year = new FormControl(null, Validators.required)
  location = new FormControl(null, Validators.required)
  fundings = new FormArray([])
  beneficiaries = new FormArray([])
  namevaluelocations = new FormArray([])
  status = new FormControl()
  action = new FormControl()
  tags_id = new FormControl([])
  tags = new FormControl([])

  constructor(interventionYearLocation?: InterventionYearLocation) {
    if (interventionYearLocation?.id) {
      this.id.setValue(interventionYearLocation?.id)
    }
    if (interventionYearLocation?.status) {
      this.status.setValue(interventionYearLocation?.status)
    }
    if (interventionYearLocation?.action) {
      this.action.setValue(interventionYearLocation?.action)
    }
    if (interventionYearLocation?.intervention_year) {
      this.intervention_year.setValue(interventionYearLocation?.intervention_year)
    }
    if (interventionYearLocation?.location) {
      this.location.setValue(interventionYearLocation?.location)
    }
    if (interventionYearLocation?.fundings) {
      const controls = interventionYearLocation?.fundings.map(item => {
        return new FormBuilder().group(new FundingForm(new Funding(item)))
      });
      controls.map(ctrl => this.fundings.push(ctrl))
    }
    if (interventionYearLocation?.beneficiaries) {
      const controls = interventionYearLocation?.beneficiaries.map(item => {
        return new FormBuilder().group(new BeneficiaryForm(new Beneficiary(item)))
      });
      controls.map(ctrl => this.beneficiaries.push(ctrl))
    }
    if (interventionYearLocation?.namevaluelocations) {
      const controls = interventionYearLocation?.namevaluelocations.map(item => {
        return new FormBuilder().group(new NamevalueForm(new Namevalue(item)))
      });
      controls.map(ctrl => this.namevaluelocations.push(ctrl))
    }
    if(interventionYearLocation.tags){
      this.tags_id.setValue(interventionYearLocation.tags.map(t => t.id))
      this.tags.setValue(interventionYearLocation.tags)
    }
  }
}

export class InterventionYearForm {
  id = new FormControl()
  intervention = new FormControl()
  year = new FormControl()
  locations = new FormArray([])

  constructor(interventionYear: InterventionYear) {

    if (interventionYear.id) {
      this.id.setValue(interventionYear.id)
    }
    if (interventionYear.intervention) {
      this.intervention.setValue(interventionYear.intervention)
    }
    if (interventionYear.year) {
      this.year.setValue(interventionYear.year)
    }
    if (interventionYear.locations) {
      const controls = interventionYear.locations.sort((a,b)=> a.location.name.localeCompare(b.location.name)).map(item => {
        return new FormBuilder().group(new YearLocationForm(new InterventionYearLocation(item)))
      });
      controls.map(ctrl => this.locations.push(ctrl));
    }
  }
}

export class InterventionForm {
  id = new FormControl()
  name = new FormControl(null, Validators.required)
  project = new FormControl()
  intervention_type = new FormControl()
  intervention_type_name = new FormControl()
  spec_type = new FormControl(null, Validators.required)
  spec_type_name = new FormControl()
  signature_solution = new FormControl(null, Validators.required)
  signature_solution_name = new FormControl()
  sdg = new FormControl(null, Validators.required)
  sdg_name = new FormControl()
  comment = new FormControl()
  years = new FormArray([])
  organization = new FormControl()
  tags_id = new FormControl([])

  constructor(intervention: Intervention) {

    if (intervention.id) {
      this.id.setValue(intervention.id)
    }
    if (intervention.name) {
      this.name.setValue(intervention.name)
    }
    if (intervention.project) {
      this.project.setValue(intervention.project)
    }
    if (intervention.intervention_type) {
      this.intervention_type.setValue(intervention.intervention_type)
    }
    if (intervention.spec_type) {
      this.spec_type.setValue(intervention.spec_type)
    }
    if (intervention.signature_solution) {
      this.signature_solution.setValue(intervention.signature_solution)
    }
    if (intervention.sdg) {
      this.sdg.setValue(intervention.sdg)
    }
    if (intervention.comment) {
      this.comment.setValue(intervention.comment)
    }
    if (intervention.years) {
      const controls = intervention.years.map(item => {
        return new FormBuilder().group(new InterventionYearForm(new InterventionYear(item)))
      });
      controls.map(ctrl => this.years.push(ctrl));
    }
    if (intervention.organization) {
      this.organization.setValue(intervention.organization)
    }
    if(intervention?.tags){
      this.tags_id.setValue(intervention?.tags.map(t => t.id))
    }
  }
}
