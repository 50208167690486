import { FormControl, Validators } from '@angular/forms';

import { Namevalue } from './namevalue.model';

export class NamevalueForm {
  id = new FormControl()
  intervention_year_location = new FormControl()
  namevalue = new FormControl(null, Validators.required)
  value = new FormControl(null, Validators.required)

  constructor(nv: Namevalue) {
    this.id.setValue(nv?.id || null)
    this.intervention_year_location.setValue(nv?.intervention_year_location)
    this.namevalue.setValue(nv?.namevalue)
    this.value.setValue(nv?.value)
  }
}
