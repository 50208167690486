import { Component, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from "../../_services/authentication.service";
import { InterventionService } from '../intervention.service';
import { InterventionReportDetailsComponent } from '../intervention-report-details/intervention-report-details.component';
import { MatDialog } from '@angular/material/dialog';

const options = [
  { label: 'Report By Location', value: 'reportbylocation' },
  { label: 'Report By Donor', value: 'reportbydonor' },
];

@Component({
  selector: 'app-intervention-report',
  templateUrl: './intervention-report.component.html',
  styleUrls: ['./intervention-report.component.scss'],
})
export class InterventionReportComponent implements OnInit {
  currentUser;
  id: number;
  report: Object;
  locations = [];
  reportType = 'reportintbylocation';
  sum = {}


  constructor(
    private interventionService: InterventionService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.id = +this.route.snapshot.paramMap.get('id');
    this.changeReportType(this.reportType);
  }

  changeReportType(type: string){
    this.interventionService.reportByType(this.id, type).subscribe(report => {
      this.report = report;
      const years = Object.keys(report);
      const locSet = new Set<string>();
      this.sum={}
      for (const year of years) {
        const locPerYear = Object.keys(report[year]);
        for (const loc of locPerYear) {
          locSet.add(loc)
          if(!this.sum[loc])this.sum[loc]={}
          const types = Object.keys(report[year][loc]['total']);
          for (const type of types) {
            if(!this.sum[loc][type])this.sum[loc][type]=0;
            this.sum[loc][type] += report[year][loc]['total'][type]
          }
      }
      }
      this.locations=Array.from(locSet).sort((a, b) => a.localeCompare(b, "sr-RS"))
    })
  }

  getFundingSum(loc: string, year:string, type: string){
    return  this.report?.[year]?.[loc]?.['total']?.[type] ?? 0;
  }

  dataKeys(data) {
    return Object.keys(data);
  }

  reportBy(event:MatRadioChange){
    this.reportType = event.value;
    this.changeReportType(this.reportType);
  }

  exitDialog(): void {
    this.router.navigate(['/'], { queryParams: { project: this.id } });
  }

  details(location: string): void {
    let donorsLocations = []
    const years = Object.keys(this.report);
    const locSet = new Set<string>()
    const sumLoc = {}
    for (const year of years) {
      const donors = Object.keys(this.report[year]?.[location] || []);   
      for (const donor of donors) {
        locSet.add(donor)
        if(!sumLoc[donor]) sumLoc[donor]={}
        const types = Object.keys(this.report[year][location][donor]);
        for (const type of types) {
          if(!sumLoc[donor][type])sumLoc[donor][type]=0;
          sumLoc[donor][type] += this.report[year][location][donor][type]
        }
      }
    }
    
    donorsLocations=Array.from(locSet).sort((a, b) =>a.localeCompare(b, "sr-RS"));
    const index = donorsLocations.findIndex(e => e==='total');
    donorsLocations.splice(index,1);
    donorsLocations.push('total')
    
    const dialogRef = this.dialog.open(InterventionReportDetailsComponent, {
      data: {
        donor: location,
        locations: donorsLocations,
        sum: sumLoc,
        report: this.report,
        reportType: this.reportType,
        intervention: this.id
      },
      width: '90vw !important'
    });
  }

}
