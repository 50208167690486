import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { Beneficiary, BeneficiaryCategoryType, BENEFICIARY_TYPES } from './beneficiaries.model';
import { BeneficiaryForm } from './beneficiaries.form';
import { BeneficiariesDeleteComponent } from './beneficiaries-delete/beneficiaries-delete.component';
import { BeneficiaryCategoryService } from './beneficiaries.service';


@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss']
})
export class BeneficiariesComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() canEdit: boolean;
  
  TYPES: any[] = BENEFICIARY_TYPES;
  CATEGORY: BeneficiaryCategoryType[]
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayedColumns: string[] = ['name', 'type', 'category', 'female', 'male', 'total', 'comment', 'delete'];
  beneficiaries;

  constructor(
    private formBuilder: FormBuilder,
    private beneficiaryCategoryService: BeneficiaryCategoryService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.beneficiaries = this.form.get('beneficiaries') as FormArray;
    this.beneficiaryCategoryService.list().subscribe( categories => {
      this.CATEGORY = categories;
      this.updateView();
    })
    this.updateView();
  }

  addBeneficiary(): void {
    let beneficiary = new Beneficiary();
    beneficiary.intervention_year_location = this.form.value.id;
    let beneficiaryForm = this.formBuilder.group(new BeneficiaryForm(beneficiary));
    this.beneficiaries.insert(0, beneficiaryForm);
    this.updateView();
    this.form.markAsDirty();
  }

  deleteDialog(beneficiary, index): void {
    if (beneficiary.value.id) {
      const dialogRef = this.dialog.open(BeneficiariesDeleteComponent, {
        data: beneficiary
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.beneficiaries.removeAt(index);
          this.updateView();
        }
      });
    } else {
      this.beneficiaries.removeAt(index);
      this.updateView();
    }
  }

  getTotal(): number {
    return this.beneficiaries.value.map(f => f.total).reduce((a, b) => a + b, 0);
  }

  getFemaleTotal(): number {
    return this.beneficiaries.value.map(f => f.female).reduce((a, b) => a + b, 0);
  }

  getMaleTotal(): number {
    return this.beneficiaries.value.map(f => f.male).reduce((a, b) => a + b, 0);
  }

  compareType(option: string, item: any): boolean {
    if (!item) return;
    return option === item;
  }

  updateView() {
    this.dataSource.next(this.beneficiaries.controls);
  }
}
