import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


export class SignatureSolution {
  id: number;
  name: string;
  organization: number;
  is_active:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SignatureSolutionService {

  baseUrl = '/api/signature-solutions/';

  constructor(private http: HttpClient) { }

  list(): Observable<SignatureSolution[]> {
    return this.http.get<SignatureSolution[]>(this.baseUrl);
  }
}
