import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './user.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    UserService
  ],
  exports: []
})
export class UserModule { }
