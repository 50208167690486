import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NamevalueService } from '../namevalue.service';


@Component({
  selector: 'app-namevalue-delete',
  templateUrl: './namevalue-delete.component.html',
  styleUrls: ['./namevalue-delete.component.scss']
})
export class NamevalueDeleteComponent {

  namevalue;

  constructor(
    private snackBar: MatSnackBar,
    private namevalueService: NamevalueService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.namevalue = data;
  }

  deleteNamevalue() {
    this.namevalueService.delete(this.namevalue.value.id).subscribe((r) => {
      this.snackBar.open('Donor deleted successfully', 'delete', { duration: 2000 });
    });

  }
}
