import { Component, Inject } from '@angular/core';
import { Form, FormArray, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeneficiaryForm } from 'src/app/beneficiaries/beneficiaries.form';
import { Beneficiary } from 'src/app/beneficiaries/beneficiaries.model';
import { BeneficiaryService } from 'src/app/beneficiaries/beneficiaries.service';
import { FundingForm } from 'src/app/funding/funding.form';
import { Funding } from 'src/app/funding/funding.model';
import { FundingService } from 'src/app/funding/funding.service';
import { YearLocationForm } from '../intervention.form';
import { Intervention } from '../intervention.model';
import { YearLocationService } from '../intervention.service';
import { Namevalue } from 'src/app/namevalue/namevalue.model';
import { NamevalueForm } from 'src/app/namevalue/namevalue.form';
import { NamevalueService } from 'src/app/namevalue/namevalue.service';

@Component({
  selector: 'app-intervention-year-copy',
  templateUrl: './intervention-year-copy.component.html',
  styleUrls: ['./intervention-year-copy.component.scss']
})
export class InterventionYearCopyComponent {

  intervention: Intervention;
  year: number;
  selectYears: FormArray;
  selectedYear: number;
  overwrite = false;
  selectYearExclude: {year:number, index: number}[]
  constructor(
    private snackBar: MatSnackBar,
    private yearLocationService: YearLocationService,
    private beneficiaryService: BeneficiaryService,
    private fundingService: FundingService,
    private namevalueSerivce: NamevalueService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<InterventionYearCopyComponent>,
  ) {
    this.intervention = data.intervention;
    this.year = data.year
    this.selectYears = data.years;
    this.selectedYear = 0;
    this.selectYearExclude = data.years.value.map((y: { year: number; }, index: number) => {return {year: y.year, index: index}})
    this.selectYearExclude.splice(data.year,1)
  }

  yearChangeSelect(event: MatSelectChange) {
    this.selectedYear = event.value;
  }

  overwriteData(selected: any): void {
    this.overwrite = selected;
  }

  async copyIntervention(): Promise<boolean> {
    try {
      const selectedYearValue = this.selectYears.controls[this.selectedYear].value
      const locations = this.selectYears.controls[this.selectedYear].get('locations') as FormArray;

      for (let yearLocation of this.selectYears.controls[this.year].value.locations) {
        let beneficiaries: FormArray;
        let fundings: FormArray;
        let namevaluelocations: FormArray;
        let index = selectedYearValue.locations.findIndex(l => l.location.id === yearLocation.location.id);
        let yearLocationNew = selectedYearValue.locations.find(l => l.location.id === yearLocation.location.id)

        if (yearLocationNew === undefined) {
          yearLocationNew = await this.yearLocationService.create({ ...yearLocation, intervention_year: selectedYearValue.id, fundings: [], beneficiaries: [],  namevaluelocations:[], tags_id: yearLocation.tags.map( t => t.id) }).toPromise();
          let yearLocationForm = new YearLocationForm(yearLocationNew);
          const values = [...locations.value]
          values.push(yearLocationNew);
          const sortedLocations = values.sort((a, b) => a.location.name.localeCompare(b.location.name))
          index = sortedLocations.findIndex(a => a.id === yearLocationNew.id)
          locations.insert(index, this.formBuilder.group(yearLocationForm));
        }

        beneficiaries = locations.controls[index].get('beneficiaries') as FormArray;
        fundings = locations.controls[index].get('fundings') as FormArray;
        namevaluelocations = locations.controls[index].get('namevaluelocations') as FormArray;

        if (this.overwrite) {
          for (let beneficiary of yearLocationNew.beneficiaries) {
            if (beneficiary.id) await this.beneficiaryService.delete(beneficiary.id).toPromise()
          }
          beneficiaries.clear()
          for (let funding of yearLocationNew.fundings) {
            if (funding.id) await this.fundingService.delete(funding.id).toPromise()
          }
          fundings.clear()

          for (let namevaluelocation of yearLocationNew.namevaluelocations) {
            if (namevaluelocation.id) await this.namevalueSerivce.delete(namevaluelocation.id).toPromise()
          }
          namevaluelocations.clear()
        }

        for (let beneficiary of yearLocation.beneficiaries) {
          let beneficiaryNew = new Beneficiary({ ...beneficiary, id: null });
          beneficiaryNew.intervention_year_location = yearLocationNew.id;
          let beneficiaryForm = this.formBuilder.group(new BeneficiaryForm(beneficiaryNew));
          beneficiaries.insert(0, beneficiaryForm);
        }
        for (let funding of yearLocation.fundings) {
          let fundingNew = new Funding({ ...funding, id: null });
          fundingNew.intervention_year_location = yearLocationNew.id;
          fundings.insert(0, this.formBuilder.group(new FundingForm(fundingNew)));
        }

        let addNameValue = [];
        if(this.overwrite){
          addNameValue = yearLocation.namevaluelocations;
        } else {
          const array = yearLocationNew.namevaluelocations.map((nv) => nv.namevalue)
          addNameValue = yearLocation.namevaluelocations.filter( nv => !array.includes(nv.namevalue))
        }
        for (let namevalue of addNameValue) {
          let namevalueNew = new Namevalue({ ...namevalue, id: null });
          namevalueNew.intervention_year_location = yearLocationNew.id;
          namevaluelocations.insert(0, this.formBuilder.group(new NamevalueForm(namevalueNew)));
        }

      }
      locations.markAsDirty();

      this.snackBar.open('Copied successfully!', 'copy', {
        duration: 2000,
      });
      this.dialogRef.close(true)
      return true
    } catch (error) {
      console.log(error)
      this.snackBar.open('Failed to copy intervention!', 'copy', {
        duration: 2000,
      });
      this.dialogRef.close(false)
      return false
    }
  }
}
