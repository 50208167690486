import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, AbstractControl } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { Project } from '../project/project.model';
import { ProjectService } from '../project/project.service';
import {NamevaluePairService} from './namevalue-pair.service'

import { NamevalueDeleteComponent } from './namevalue-delete/namevalue-delete.component';
import { Namevalue, NamevaluePair } from './namevalue.model';
import {NamevalueForm} from './namevalue.form'

@Component({
  selector: 'app-namevalue',
  templateUrl: './namevalue.component.html',
  styleUrls: ['./namevalue.component.scss']
})
export class NamevalueComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() canEdit: boolean;
  @Input() yearForm: FormGroup;
  
  activeProject: Project | null;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayedColumns: string[] = ['name', 'value','delete'];
  namevaluelocations;
  pairs: NamevaluePair[] = []

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private namevaluePairsService: NamevaluePairService,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    this.namevaluelocations = this.form.get('namevaluelocations') as FormArray;
    this.projectService.currentProject.subscribe(project => {
      this.activeProject = project;
    });
    this.namevaluePairsService.list().subscribe(pairs => {
      this.pairs = pairs;
    } )

    this.updateView();
  }

  async addNamevalue(): Promise<void> {
    let nv = new Namevalue();
    nv.intervention_year_location = this.form.value.id;
    let namevalueForm = this.formBuilder.group(new NamevalueForm(nv));
    this.namevaluelocations.insert(0, namevalueForm);
    this.updateView();
    this.form.markAsDirty();
  }

  deleteDialog(nv, index: number): void {

    if (nv.value.id) {
      const dialogRef = this.dialog.open(NamevalueDeleteComponent, {
        data: nv
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.namevaluelocations.removeAt(index);
          this.updateView();
        }
      });
    } else {
      this.namevaluelocations.removeAt(index);
      this.updateView();
    }

  }

  delete() { }

  getTotalSum() {
    return parseFloat(this.namevaluelocations.value.map(f => f.sum).reduce((a, b) => a + b, 0).toFixed(2));
  }

  updateView() {
    this.dataSource.next(this.namevaluelocations.controls);
  }

  isSelect(nv: FormGroup){
    const t = this.pairs.filter(n => n.id===nv.value.namevalue)[0]?.type
    return  t === 'Select' ? 1 : (t === 'Text' ? 2 : 3)
  }

  getSelectOptions(nv: FormGroup){
    return this.pairs.filter(n => n.id===nv.value.namevalue)[0].selectOptions;
  }

  isDisabled(n: NamevaluePair){
    if(!n.is_active) return true
    return this.namevaluelocations.value.findIndex( nv => nv.namevalue === n.id) !=-1
  }

}
