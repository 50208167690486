import { InterventionYearLocation } from '../intervention/intervention.model';

export const BENEFICIARY_TYPES: any[] = [
  { name: 'Direct', value: 'direct' },
  { name: 'Indirect', value: 'indirect' }
];

export class BeneficiaryCategoryType {
  id: number;
  name: string;
  is_active: boolean;
}

export class Beneficiary {
  id: number;
  intervention_year_location: InterventionYearLocation | number;
  female: number;
  male: number;
  total: number;
  type: string;
  name: string;
  beneficiary_category: number | BeneficiaryCategoryType;
  comment: string;

  constructor(beneficiary?: Beneficiary) {
    this.id = beneficiary?.id;
    this.intervention_year_location = beneficiary?.intervention_year_location;
    this.female = beneficiary?.female;
    this.male = beneficiary?.male;
    this.total = beneficiary?.total;
    this.type = beneficiary?.type;
    this.name = beneficiary?.name;
    this.beneficiary_category = beneficiary?.beneficiary_category;
    this.comment = beneficiary?.comment;
  }
}
