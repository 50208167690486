import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HEPEC';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      `olovka`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/olovka.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `kanta`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/kanta.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `plus`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/plus.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `completed`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/completed.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `ongoing`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/ongoing.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `planned`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/planned.svg")
    );
  }
}
