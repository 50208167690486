import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Intervention } from '../intervention.model';
import { InterventionService } from '../intervention.service';

@Component({
  selector: 'app-intervention-delete',
  templateUrl: './intervention-delete.component.html',
  styleUrls: ['./intervention-delete.component.scss']
})
export class InterventionDeleteComponent {

  intervention: Intervention;

  constructor(
    private snackBar: MatSnackBar,
    private interventionService: InterventionService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.intervention = data;
  }
  deleteIntervention(): boolean {
    this.interventionService.delete(this.intervention.id).subscribe(result => {
      this.snackBar.open('Deleted successfully!', 'delete', {
        duration: 2000,
      });
    });
    return true;
  }
}
