<h2 mat-dialog-title>Copy Year {{selectYears?.value[year]?.year}}</h2>
<mat-dialog-content class="mat-typography" style="width: 400px;">
  <div>
    <mat-label style="font-size:small; opacity:'0.7'">Copy To Year</mat-label>
    <div class="select-year">
      <mat-select (selectionChange)="yearChangeSelect($event)" [value]="selectedYear">
        <mat-option *ngFor="let control of selectYearExclude; index as i" [value]="control.index">
          {{ control.year }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div style="margin-bottom: 16px;">
    <mat-checkbox (change)="overwriteData($event.checked)" color="primary">
      Overwrite
    </mat-checkbox>
    <div style="color:red" *ngIf="overwrite">
      <i>
        If data is selected to be overwritten, locations and all underlying related data 
        (funding, beneficiaries) that exist are replaced.Locations that do not exist are created.
      </i>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)='copyIntervention()'>Copy Year</button>
</mat-dialog-actions>
