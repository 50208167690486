import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, AbstractControl } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { Project } from '../project/project.model';
import { ProjectService } from '../project/project.service';

import { Funding, FUNDING_TYPES } from './funding.model';
import { FundingForm } from './funding.form';
import { FundingDeleteComponent } from './funding-delete/funding-delete.component';
import { FundingAddDialogComponent } from './funding-add-dialog/funding-add-dialog.component';


@Component({
  selector: 'app-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss']
})
export class FundingComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() canEdit: boolean;
  @Input() yearForm: FormGroup;
  
  activeProject: Project;
  TYPES: string[] = FUNDING_TYPES;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayedColumns: string[] = ['donor', 'type', 'sum', 'delete'];
  fundings;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    
    this.fundings = this.form.get('fundings') as FormArray;
    this.projectService.currentProject.subscribe(project => {
      this.activeProject = project;
    });

    this.updateView();
  }

  createFunding(funding: Funding): FormGroup {
    return this.formBuilder.group(new FundingForm(new Funding(funding)));
  }

  
  async addFunding(): Promise<void> {
    const dialogRef = this.dialog.open(FundingAddDialogComponent, {
      data: {
        year: this.yearForm,
      }

    });

    await dialogRef.afterClosed().toPromise()
  }



  deleteDialog(funding, index: number): void {

    if (funding.value.id) {
      const dialogRef = this.dialog.open(FundingDeleteComponent, {
        data: funding
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.fundings.removeAt(index);
          this.updateView();
        }
      });
    } else {
      this.fundings.removeAt(index);
      this.updateView();
    }

  }

  delete() { }

  getTotalSum() {
    return parseFloat(this.fundings.value.map(f => f.sum).reduce((a, b) => a + b, 0).toFixed(2));
  }

  compareDonor(option: string, value: string): boolean {
    if (!value) return;
    return option === value;
  }

  compareType(option: string, value: string): boolean {
    if (!value) return;
    return option === value;
  }

  updateView() {
    this.dataSource.next(this.fundings.controls);
  }

}
