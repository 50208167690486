import { Component } from '@angular/core';
import { MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-location-delete',
  templateUrl: './location-delete.component.html',
  styleUrls: ['./location-delete.component.scss']
})
export class LocationDeleteComponent {

  constructor(
    private dialogRef: MatDialogRef<LocationDeleteComponent>,
  ) { }

  saveChanges(): any {
    this.dialogRef.close(true);
  }

  close(): any {
    this.dialogRef.close(false);
  }
}
