import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InterventionService } from '../intervention.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-intervention-report-details',
  templateUrl: './intervention-report-details.component.html',
  styleUrls: ['./intervention-report-details.component.scss']
})
export class InterventionReportDetailsComponent implements OnInit {

  

  donor: string;
  locations: [];
  sum : Object;
  report: Object;
  reportType: string;
  intervention: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private interventionService: InterventionService
  ) { 
    this.donor = data.donor;
    this.locations = data.locations;
    this.sum = data.sum;
    this.report = data.report;
    this.reportType = data.reportType;
    this.intervention = data.intervention;
  }

  getFundingSum(location: string, year:string, type: string){
    return  this.report?.[year]?.[this.donor]?.[location]?.[type] ?? 0;
  }

  dataKeys(data) {
    return Object.keys(data);
  }

  ngOnInit(): void {}

  exportReport(){
    console.log(this.reportType)
    if(this.reportType=='reportintbylocation'){
      console.log("USLO")
      this.interventionService.exportReportByLocatioin(this.intervention,this.donor).subscribe( data => {
        saveAs(data, `report${this.donor}.xlsx`)
      }) 
    } else {
      this.interventionService.exportReportByDonor(this.intervention,this.donor).subscribe( data => {
        saveAs(data, `report${this.donor}.xlsx`)
      }) 
    }
    
  }
}

