import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'src/app/locations/locations.service';
import { Project } from 'src/app/project/project.model';
import { ProjectService } from 'src/app/project/project.service';
import { FundingForm } from '../funding.form';
import { Funding, FUNDING_TYPES } from '../funding.model';
import { FundingService } from '../funding.service';


@Component({
  selector: 'funding-add-dialog',
  templateUrl: './funding-add-dialog.component.html',
  styleUrls: ['./funding-add-dialog.component.scss']
})
export class FundingAddDialogComponent {

  @ViewChild('allSelected') private allSelected: MatOption;

  fundingForm = this.formBuilder.group(new FundingForm(new Funding()));
  activeProject: Project;
  TYPES: string[] = FUNDING_TYPES;
  year: FormGroup;
  locations: FormArray

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.year = data.year;
    this.locations = this.year.get('locations') as FormArray;
    this.fundingForm.addControl('locations', this.formBuilder.control('', Validators.required));

    this.locationService.currentLocation.subscribe(location => {
      if (!location) return;
      this.fundingForm.controls.locations
        .patchValue([location.id]);
    });
    this.projectService.currentProject.subscribe(project => {
      this.activeProject = project;
    });
  }

  addFunding() {
    for (let location of this.fundingForm.value.locations) {
      let index = this.locations.value.findIndex(loc => loc.location.id === location);
      if(index===-1) continue;
      const fundings = this.locations.controls[index].get('fundings') as FormArray;
      let fundingNew = new Funding({ ...this.fundingForm.value, intervention_year_location: this.locations.value[index].id });
      fundings.insert(0, this.formBuilder.group(new FundingForm(fundingNew)));
    }
    this.snackBar.open('Fundings Added', 'Success', { duration: 2000 });

  }

  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.fundingForm.controls.locations.value.length == this.locations.length)
      this.allSelected.select();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.fundingForm.controls.locations
        .patchValue([...this.locations.value.map(loc => loc.location.id), -1]);
    } else {
      this.fundingForm.controls.locations.patchValue([]);
    }
  }
}
