import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from "@angular/common";
import { ActivatedRoute, Route, Router } from "@angular/router";

import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Intervention } from '../intervention.model';
import { InterventionService } from '../intervention.service';
import { InterventionDeleteComponent } from '../intervention-delete/intervention-delete.component';

import { Project } from '../../project/project.model';
import { ProjectService } from '../../project/project.service';

import { LocationService } from "../../locations/locations.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { InterventionCopyComponent } from '../intervention-copy/intervention-copy.component';


@Component({
  selector: 'app-intervention-list',
  templateUrl: './intervention-list.component.html',
  styleUrls: ['./intervention-list.component.scss']
})
export class InterventionListComponent implements OnInit {

  dataSource;
  projects: Project[] = [];
  activeProject: Project;
  intervention: Intervention;
  currentUser;
  canEdit: boolean;
  open = false;
  project_data;
  totalBeneficiarires: number = 0;
  totalAmount: number = 0;
  loading = false;

  displayedColumns: string[] = ['status', 'name', 'intervention_type_name', 'spec_type', 'signature_solution', 'sdg', 'amount', 'beneficiaries_total', 'edit', 'copy','report', 'delete'];
  comment(row): string {
    let locationNames = row.years.map(year => year.locations.map(item => item.location.name)).reduce((accum, el) => accum.concat(el), []);
    return 'Locations:\n' + locationNames;
    // let donorNames = row.years.map(year => year.locations.map(item => item.fundings.map(funding => funding.donor_name + ' ' + funding.sum))).reduce((accum, el) => accum.concat(el), []).reduce((accum, el) => accum.concat(el), []);
    // return 'Locations:\n' + locationNames + '\n\n' + 'Donors:\n' + donorNames;
  }

  @ViewChild(MatSort) sort: MatSort;
 

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private interventionService: InterventionService,
    private projectService: ProjectService,
    private locationService: LocationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    
    this.projectService.currentProject.subscribe(project => {
      if (project && (project?.id!= this.activeProject?.id)) {
        this.activeProject = project;
        this.canEdit = this.currentUser.user.is_superuser || (this.currentUser.user.projects.find(p => p=== project.id)!=undefined)
        this.reload(project.id);
      }
    });
  }

  updateDialog(intervention: Intervention): void {
    this.router.navigate(['intervention/' + intervention.id], { queryParams: { project: this.activeProject.id } });
  }

  deleteDialog(intervention: Intervention): void {
    let fundings = intervention.years.map(year => year.locations.map(yearLocation => yearLocation.fundings)).reduce((accum, el) => accum.concat(el), []).reduce((accum, el) => accum.concat(el), []);
    let beneficiaries = intervention.years.map(year => year.locations.map(yearLocation => yearLocation.beneficiaries)).reduce((accum, el) => accum.concat(el), []).reduce((accum, el) => accum.concat(el), []);
    if (fundings.length || beneficiaries.length) {
      let message = fundings.length ? fundings.length + ' funding/s' : '';
      message += beneficiaries.length && fundings.length ? ' and ' : '';
      message += beneficiaries.length ? beneficiaries.length + ' beneficiar(y)ies' : '';
      this.snackBar.open('You have ' + message + '. First delete them and than you can delete intervention.', 'Cancel', {
        duration: 6000,
      });
      return;
    }

    const dialogRef = this.dialog.open(InterventionDeleteComponent, {
      data: intervention
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.reload(this.activeProject.id); }
    });
  }

  async copyDialog(intervention: Intervention): Promise<void> {
    const dialogRef = this.dialog.open(InterventionCopyComponent, {
      data: intervention
    });

    const result = await dialogRef.afterClosed().toPromise()
    if (result === true) { 
      this.reload(this.activeProject.id); 
    }
  }

  reload(projectId: number): void {
    this.loading = true;
    this.interventionService.getByProjectID(projectId).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.totalBeneficiarires = data.map(f => f.beneficiaries_total).reduce((a, b) => a + b, 0);
      this.totalAmount = parseFloat(data.map(f => f.amount).reduce((a, b) => a + b, 0).toFixed(2));
      this.loading = false;
    });
  }

  createDialog(): void {
    this.locationService.changeLocation(null);
    this.router.navigate(['intervention/create'], { queryParams: { project: this.activeProject.id } });
  }

  formatDate(date: Date): string {
    return this.activeProject ? formatDate(date, 'dd.MM.yyyy', 'en-US') : '';
  }

  canDelete(organization: number): boolean {
    return (this.canEdit && (organization === this.currentUser.user.organization)) || this.currentUser.user.is_superuser
  }

  interventionReport(intervention:Intervention): void {
    this.router.navigate(['interventionreport/'+intervention.id],  { queryParams: { project: this.activeProject.id } });
  }
}
