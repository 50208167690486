import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Funding } from './funding.model';


@Injectable({
  providedIn: 'root'
})
export class FundingService {

  baseUrl = '/api/fundings/';

  constructor(private http: HttpClient) { }

  list(): Observable<Funding[]> {
    return this.http.get<Funding[]>(this.baseUrl);
  }

  getByIdInterventionYearLocation(interventionId: number): Observable<Funding> {
    return this.http.get<Funding>(this.baseUrl + '?intervention_year_location=' + interventionId);
  }

  create(funding: Funding): Observable<Funding> {
    return this.http.post<Funding>(this.baseUrl, funding);
  }

  update(funding: Funding): Observable<Funding> {
    return this.http.patch<Funding>(this.baseUrl + funding.id + '/', funding);
  }

  delete(fundingID: number) {
    return this.http.delete(this.baseUrl + fundingID + '/');
  }
}
