<mat-dialog-content class="mat-typography">
  <form [formGroup]="addProjectForm" autocomplete="chrome-off">
    <h2 mat-dialog-title>Add Project</h2>
    <div>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Project name</mat-label>
        <input matInput placeholder="Project name" formControlName="name" md-clear-button="true" required />
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Project short name</mat-label>
      <input matInput placeholder="Project short name" formControlName="short_name" md-clear-button="true" required />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Project status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="status[0]"> Planned </mat-option>
        <mat-option [value]="status[1]"> Ongoing </mat-option>
        <mat-option [value]="status[2]"> Completed </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 200px">
      <mat-label>Project start date</mat-label>
      <input matInput [matDatepicker]="dp_start" formControlName="date_start">
      <mat-datepicker-toggle matSuffix [for]="dp_start"></mat-datepicker-toggle>
      <mat-datepicker #dp_start></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 200px">
      <mat-label>Project end date</mat-label>
      <input matInput [matDatepicker]="dp_end" formControlName="date_end"
        [min]="addProjectForm.controls.date_start.value">
      <mat-datepicker-toggle matSuffix [for]="dp_end"></mat-datepicker-toggle>
      <mat-datepicker #dp_end></mat-datepicker>
    </mat-form-field>

    <div>
      <mat-form-field appearance="fill" style="width: 50%">
        <mat-label>Contact person</mat-label>
        <input matInput placeholder="Contact person" formControlName="contact_person" md-clear-button="true" required />
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 49%">
        <mat-label>Contact person e-mail</mat-label>
        <input matInput placeholder="Contact person e-mail" formControlName="contact_email" md-clear-button="true"
          required />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Comment</mat-label>
        <textarea matInput placeholder="..." formControlName="comments" rows=1></textarea>
      </mat-form-field>
    </div>

    <div style="display:flex; width: 100%;">
      <div style="width: 50%;">
        <div>
          <div class="selectedTitle">Donors</div>
          <div class="selectedList">
            <div *ngFor="let option of chosenDonors" class="selectedValue">
              {{option}}
              <mat-icon  (click)="remove(option)" class="removeIcon">cancel</mat-icon>
            </div>
          </div>
        </div>
        <mat-form-field style="width:100%">
          <input placeholder="Choose donor..." #donorInput [formControl]="donorCtrl" matInput
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let option of autocompleteFilteredDonors| async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div style="width: 50%; margin-left: 32px;">
        <div>
          <div class="selectedTitle">Organizations</div>
          <div class="selectedList">
            <div *ngFor="let option of chosenOrganizations" class="selectedValue">
              {{option}}
              <mat-icon (click)="removeOrg(option)" class="removeIcon">cancel</mat-icon>
            </div>
          </div>
        </div>
        <mat-form-field style="width:100%">
          <input  placeholder="Choose organizations..." #orgInput [formControl]="orgCtrl" matInput
            [matAutocomplete]="autoOrg">
          <mat-autocomplete #autoOrg="matAutocomplete" (optionSelected)="selectedOrg($event)">
            <mat-option *ngFor="let option of autocompleteFilteredOrganizations| async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

    </div>

    <!-- <mat-form-field style="width:100%">
      <mat-chip-list #chipListOrg aria-label="Organization selection">
        <mat-chip *ngFor="let option of chosenOrganizations" [selectable]="true" [removable]="true"
          (removed)="removeOrg(option)">
          {{option}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Choose organizations..." #orgInput [formControl]="orgCtrl" [matChipInputFor]="chipListOrg"
          [matAutocomplete]="autoOrg">
      </mat-chip-list>
      <mat-autocomplete #autoOrg="matAutocomplete" (optionSelected)="selectedOrg($event)">
        <mat-option *ngFor="let option of autocompleteFilteredOrganizations| async" [value]="option.name">
          {{option.short_name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <br />

    <mat-form-field style="width:100%">
      <mat-chip-list #chipList aria-label="Donor selection">
        <mat-chip *ngFor="let option of chosenDonors" [selectable]="true" [removable]="true" (removed)="remove(option)">
          {{option}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Choose donor..." #donorInput [formControl]="donorCtrl" [matChipInputFor]="chipList"
          [matAutocomplete]="auto">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of autocompleteFilteredDonors| async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->

    <div>

    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)='onSubmit()'
    [disabled]="!addProjectForm.valid || !chosenDonors.length || !chosenOrganizations.length">Submit</button>
</mat-dialog-actions>
