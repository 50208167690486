import { Beneficiary } from '../beneficiaries/beneficiaries.model';
import { Funding } from '../funding/funding.model';
import { Namevalue } from '../namevalue/namevalue.model';


export const INTERVENTION_STATUS = [
  { name: 'Planned', value: 'planned' },
  { name: 'Ongoing', value: 'ongoing' },
  { name: 'Completed', value: 'completed' }
];


export class Tag {
  id: number;
  name: string;
  is_active: boolean;
}

export class Intervention {
  id: number;
  name: string;
  project: number;
  project_name: string;
  intervention_type: number;
  intervention_type_name: string;
  spec_type: number;
  spec_type_name: string;
  signature_solution: number;
  signature_solution_name: string;
  sdg: number;
  sdg_name: string;
  status: string;
  comment: string;
  years: InterventionYear[];
  amount: number;
  beneficiaries_total: number;
  organization: number;
  tags: Tag[];
  tags_id: number[];

  constructor(intervention?: Intervention) {
    this.id = intervention?.id
    this.name = intervention?.name
    this.project = intervention?.project
    this.project_name = intervention?.project_name
    this.intervention_type = intervention?.intervention_type
    this.intervention_type_name = intervention?.intervention_type_name
    this.spec_type = intervention?.spec_type
    this.spec_type_name = intervention?.spec_type_name
    this.signature_solution = intervention?.signature_solution
    this.signature_solution_name = intervention?.signature_solution_name
    this.sdg = intervention?.sdg
    this.sdg_name = intervention?.sdg_name
    this.status = intervention ? intervention.status : INTERVENTION_STATUS[0].value
    this.comment = intervention?.comment
    this.years = intervention?.years
    this.organization = intervention?.organization || JSON.parse(localStorage.getItem('currentUser')).user.organization
    this.tags = intervention?.tags;
    this.tags_id = intervention?.tags_id;
  }
}

export class InterventionType {
  id: number;
  name: string;
  is_active: boolean;
}

export class InterventionSpecificType {
  id: number;
  name: string;
  intervention_type: number;
  is_active: boolean;
}

export class InterventionYear {
  id: number;
  intervention: number;
  year: number;
  locations: InterventionYearLocation[];

  constructor(intervention_year?) {
    this.id = intervention_year?.id;
    this.intervention = intervention_year?.intervention;
    this.year = intervention_year?.year;
    this.locations = intervention_year?.locations;
  }
}

export class LocationType {
  id: number;
  name: string;
}


export class InterventionYearLocation {
  id: number;
  intervention_year: number;
  location: LocationType;
  fundings: Funding[];
  beneficiaries: Beneficiary[];
  namevaluelocations: Namevalue[];
  status: number;
  action: string;
  tags: Tag[];
  tags_id: number[];

  constructor(iyl?) {
    this.id = iyl?.id;
    this.status = iyl?.status;
    this.action = iyl?.action;
    this.intervention_year = iyl?.intervention_year;
    this.location = iyl?.location;
    this.fundings = iyl?.fundings;
    this.beneficiaries = iyl?.beneficiaries;
    this.namevaluelocations = iyl?.namevaluelocations;
    this.tags = iyl?.tags;
    this.tags_id = iyl?.tags ? iyl.tags.map(t => t.id) : [];
  }
}
