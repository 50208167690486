import { Component, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from "../../_services/authentication.service";
import { ProjectService } from '../project.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectReportDetailsComponent } from '../project-report-details/project-report-details.component';

const options = [
  { label: 'Report By Location', value: 'reportbylocation' },
  { label: 'Report By Donor', value: 'reportbydonor' },
];

@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: ['./project-report.component.scss'],
})
export class ProjectReportComponent implements OnInit {
  currentUser;
  id: number;
  report: Object;
  locations = [];
  reportType = 'reportbylocation';
  sum = {}
  expandedElement: string | null;
  columnsToDisplay = ['name', 'years']

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    this.id = +this.route.snapshot.paramMap.get('id');
    this.changeReportType(this.reportType);
  }

  changeReportType(type: string){
    this.projectService.reportByType(this.id, type).subscribe(report => {
      this.report = report;
      const years = Object.keys(report);
      const locSet = new Set<string>()
      this.sum = {}
      for (const year of years) {
        const locPerYear = Object.keys(report[year]);
        for (const loc of locPerYear) {
            locSet.add(loc)
            if(!this.sum[loc])this.sum[loc]={}
            const types = Object.keys(report[year][loc]['total']);
            for (const type of types) {
              if(!this.sum[loc][type])this.sum[loc][type]=0;
              this.sum[loc][type] += report[year][loc]['total'][type]
            }
        }
      }
      this.locations=Array.from(locSet).sort((a, b) => a.localeCompare(b, "sr-RS"))
    })
  }

  getFundingSum(loc: string, year:string, type: string){
    return  this.report?.[year]?.[loc]?.['total']?.[type] ?? 0;
  }

  dataKeys(data) {
    return Object.keys(data);
  }

  reportBy(event:MatRadioChange){
    this.reportType = event.value;
    this.changeReportType(this.reportType);
  }

  exitDialog(): void {
    this.router.navigate(['/'], { queryParams: { project: this.id } });
  }

  details(location: string): void {
    let donorsLocations = []
    const years = Object.keys(this.report);
    const locSet = new Set<string>()
    const sumLoc = {}
    for (const year of years) {
      const donors = Object.keys(this.report[year]?.[location] || []);   
      for (const donor of donors) {
        locSet.add(donor)
        if(!sumLoc[donor]) sumLoc[donor]={}
        const types = Object.keys(this.report[year][location][donor]);
        for (const type of types) {
          if(!sumLoc[donor][type])sumLoc[donor][type]=0;
          sumLoc[donor][type] += this.report[year][location][donor][type]
        }
      }
    }
    
    donorsLocations=Array.from(locSet).sort((a, b) => a.localeCompare(b, "sr-RS"));
    const index = donorsLocations.findIndex(e => e==='total');
    donorsLocations.splice(index,1);
    donorsLocations.push('total')
    
    const dialogRef = this.dialog.open(ProjectReportDetailsComponent, {
      data: {
        location,
        donors: donorsLocations,
        sum: sumLoc,
        report: this.report,
        reportType: this.reportType,
        project:this.id
      },
      width: '90vw !important'
    });
  }

}
