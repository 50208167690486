<mat-card class="login-form">

  <mat-card-title>
    <h2 style="display: inline-block;">LogIn</h2>
  </mat-card-title>

  <form [formGroup]="loginForm">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" />
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Password" formControlName="password" />
    </mat-form-field>

    <div *ngIf="error" class="error">{{error}}</div>
    <div>
      <button mat-raised-button color="primary" (click)="onSubmit()" cdkFocusInitial>Login</button>
    </div>

  </form>

</mat-card>
