import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class SDG {
    id: number;
    sdg_id: number;
    name: string;
    is_active: boolean
}

@Injectable({
    providedIn: 'root'
})
export class SDGService {

    baseUrl = '/api/sdgs/';

    constructor(private http: HttpClient) { }

    list(): Observable<SDG[]> {
        return this.http.get<SDG[]>(this.baseUrl);
    }
}
