import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";

import { LocationNode } from "../locations/locations.model";
import { LocationService } from "../locations/locations.service";
import { LocationsComponent } from "../locations/locations.component";

import { INTERVENTION_STATUS, InterventionYearLocation, Tag } from '../intervention/intervention.model';

import { YearLocationForm } from '../intervention/intervention.form';
import { TagService, YearLocationService } from '../intervention/intervention.service';
import { LocationCopyComponent } from '../locations/location-copy/location-copy.component';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-funding-beneficiaries',
  templateUrl: './funding-beneficiaries.component.html',
  styleUrls: ['./funding-beneficiaries.component.scss']
})
export class FundingBeneficiariesComponent implements OnInit {
  @Input() yearForm: FormGroup;
  @Input() years: FormGroup;
  @Input() year: number;
  @Input() canEdit: boolean;

  @Output() copyLocationEvent = new EventEmitter<FormGroup>();
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  currentLocation;
  currentFBTab = 0;
  currentLocationTab = 0;
  locations: FormArray = new FormArray([]);
  interventionStatus = INTERVENTION_STATUS;

  tagCtrl = new FormControl('');
  filteredTags: Observable<Tag[]>;
  tags: Tag[] = [];
  allTags: Tag[] = [];

  @ViewChild("matFuBeTabGroup", {static: false}) matFuBeTabGroup: MatTabGroup;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private yearLocationService: YearLocationService,
    private tagService: TagService
  ) {
    this.locationService.currentLocation.subscribe(location => {
      if (!location) return;
      this.currentLocation = location;
      this.currentLocationTab = this.locations.value.findIndex(loc => loc.location.id == location.id);
      if(this.currentLocationTab===-1) this.currentLocationTab = 0;
      if(this.locations.controls.length) this.tags = this.locations.controls[this.currentLocationTab].value.tags;
      if (location.hasOwnProperty('fbTab')) {
        this.currentFBTab = location.fbTab;
      }
    });
  }

  ngOnInit(): void {
    this.locations = (this.yearForm?.get('locations') as FormArray) || new FormArray([]);
    if(this.locations.controls.length) this.tags = this.locations.controls[this.currentLocationTab].value.tags;
    this.tagService.list('action').subscribe( data => {
      this.allTags = [... data]
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string) => (tag ? this._filter(tag) : this.allTags.slice())),
      );
    })
  }

  ngOnChanges(changes: any) {
    if(changes.yearForm){
      this.locations = (changes?.yearForm?.currentValue?.get('locations')  ?? this.locations) as FormArray;
      if(this.locations.controls.length) this.tags = this.locations.controls[this.currentLocationTab].value.tags;
    }
    if(changes.years) {
      this.locations = (changes.years.currentValue.controls[this.year]?.get('locations') as FormArray) || new FormArray([]);
      if(this.locations.controls.length) this.tags = this.locations.controls[this.currentLocationTab].value.tags;
    }
  }

  addLocationDialog(): void {
    const dialogRef = this.dialog.open(LocationsComponent, {
      data: this.locations.value
    });

    dialogRef.componentInstance.addLocationEvent.subscribe((location) => {
      let yearLocation = new InterventionYearLocation()
      yearLocation.location = location;
      yearLocation.intervention_year = this.yearForm.value.id;
      this.yearLocationService.create(yearLocation).subscribe(result => {
        let yearLocationForm = new YearLocationForm(result);
        const values = [... this.locations.value]
        values.push(result);
        const sortedLocations = values.sort((a,b)=> a.location.name.localeCompare(b.location.name))
        const index = sortedLocations.findIndex(a => a.id ===result.id)
        this.locations.insert(index, this.formBuilder.group(yearLocationForm));
        this.locationService.changeLocation(location);
        this.yearForm.markAsDirty();
      });

    });

    dialogRef.componentInstance.removeLocationEvent.subscribe((location) => {
      const index = this.locations.value.findIndex(loc => loc.location.id == location.id);
      if (index === -1) { return; }
      const yearLocation = this.locations.value.find(loc => loc.location.id == location.id);
      this.yearLocationService.delete(yearLocation.id).subscribe(result => {
        this.locations.removeAt(index);
        let newLocation = null;
        if (location.id == this.currentLocation.id && this.locations.value.length > 0) newLocation = this.locations.value[0].location;
        this.locationService.changeLocation(newLocation);
        this.yearForm.markAsDirty();
      });
    });
  }

  changeCurrentLocation(location: LocationNode) {
    this.locationService.changeLocation(location);
  }

  async copyLocationDialog(): Promise<void> {
    const dialogRef = this.dialog.open(LocationCopyComponent, {
      data:{ 
        years: this.years,
        index: this.currentLocationTab,
        currentLocation: this.currentLocation.id,
        year: this.year
      }
    });

    const res = await dialogRef.afterClosed().toPromise()
    
    if(res){
      this.copyLocationEvent.emit(res)
    }
  }

  trackByFn(index, entity) {

    return entity.value?.id+entity.value?.beneficiaries?.map(b =>b.id ||'n').join('') + entity.value?.fundings?.map(b =>b.id ||'n').join('');
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.locations.controls[this.currentLocationTab].get('tags_id').value.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const id: number = event.option.value
    const tag = this.tags.find( t => t.id === id )

    if(tag === undefined){
      const t = this.allTags.find(t => t.id ===id)
      this.tags.push(t)
      this.locations.controls[this.currentLocationTab].get('tags_id').value.push(t.id)
    }
    this.tagInput.nativeElement.value = null;
    this.tagCtrl.setValue(null);
    this.tagCtrl.disable();
    this.tagCtrl.enable();
  }

  private _filter(value: string): Tag[] {
    const filterValue = (''+value).toLowerCase();

    return this.allTags.filter(tag => tag.name.toLowerCase().includes(filterValue));
  }

}
