import { InterventionYearLocation } from '../intervention/intervention.model';

export type SelectOption = {
  id: number,
  name: string;
}
export type NamevaluePair = {
  id: number;
  name:string;
  type: string;
  is_active: boolean;
  selectOptions: SelectOption[];
}

export class Namevalue {
  id?: number;
  intervention_year_location?: InterventionYearLocation | number;
  namevalue?: number;
  year?: number;
  value?: string;

  constructor(nv?: Namevalue) {
    this.id = nv?.id;
    this.intervention_year_location = nv?.intervention_year_location;
    this.namevalue = nv?.namevalue;
    this.year = nv?.year;
    this.value = nv?.value;
  }
}
