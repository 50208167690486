<div style='max-height:70vh; overflow-y: overlay;'>

  <button mat-button color="primary" (click)="addFunding()" style="margin-top: 20px; font-weight: 500" *ngIf="canEdit">
    Add funding +
  </button>

  <form [formGroup]="form">

    <mat-table [dataSource]="dataSource" formArrayName="fundings">

      <ng-container matColumnDef="donor">
        <mat-header-cell *matHeaderCellDef>Donor</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field style="width: 300px;">
            <mat-label></mat-label>
            <mat-select [compareWith]="compareDonor" formControlName="donor" placeholder="Donor">
              <mat-option *ngFor="let option of activeProject.donors" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="fundings.controls[i].get('donor').hasError('required')">
              Donor is required
            </mat-error>
          </mat-form-field>

        </mat-cell>
        <mat-footer-cell *matFooterCellDef> <b>Total</b> </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select [compareWith]="compareType" formControlName="type" placeholder="Type of funding">
              <mat-option *ngFor="let option of TYPES" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="fundings.controls[i].get('type').hasError('required')">
              Type is required
            </mat-error>
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="sum">
        <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <input type="number" matInput formControlName="sum" placeholder="0" />
            <mat-error *ngIf="fundings.controls[i].get('sum').hasError('required')">
              Sum is required
            </mat-error>
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ getTotalSum() | number }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <button type="button" mat-icon-button (click)="deleteDialog(row, i)" *ngIf="canEdit">
            <mat-icon svgIcon="kanta"></mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>

  </form>

</div>
