<div style='max-height:70vh; overflow-y: overlay;'>

  <button mat-button color="primary" (click)="addBeneficiary()" style="margin-top: 20px; font-weight: 500" *ngIf="canEdit">
    Add beneficiary +
  </button>
  <form [formGroup]="form">

    <mat-table [dataSource]="dataSource" formArrayName="beneficiaries">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <input type="string" matInput formControlName="name" placeholder="Name" required />
            <mat-error *ngIf="beneficiaries.controls[i].get('name').hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> <b>Total</b> </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select [compareWith]="compareType" placeholder="Type" formControlName="type" style="width: 140px;">
              <mat-option *ngFor="let bType of TYPES" [value]="bType.value">
                {{bType.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="beneficiaries.controls[i].get('type').hasError('required')">
              Type is required
            </mat-error>
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select placeholder="Category" formControlName="beneficiary_category">
              <mat-option *ngFor="let cat of CATEGORY" [value]="cat.id" [disabled]="!cat.is_active">
                {{cat.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="beneficiaries.controls[i].get('beneficiary_category').hasError('required')">
              Category is required
            </mat-error>
          </mat-form-field>

        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="female">
        <mat-header-cell *matHeaderCellDef>Female</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <input type="number" matInput formControlName="female" placeholder="0" />
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ getFemaleTotal() | number }} </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="male">
        <mat-header-cell *matHeaderCellDef>Male</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <input type="number" matInput formControlName="male" placeholder="0" />
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ getMaleTotal() | number }} </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <mat-form-field>
            <mat-label></mat-label>
            <input type="number" matInput formControlName="total" placeholder="0" required />
          </mat-form-field>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ getTotal() | number }} </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <textarea matInput placeholder="..." formControlName="comment"></textarea>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let row; let i=index" [formGroupName]="i">
          <button type="button" mat-icon-button (click)="deleteDialog(row, i)" *ngIf="canEdit">
            <mat-icon svgIcon="kanta"></mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>

    </mat-table>

  </form>

</div>
