import { Component, OnInit } from '@angular/core';
import { ProjectService } from "../project.service";
import { Project } from "../project.model";

@Component({
  selector: 'app-project-delete',
  templateUrl: './project-delete.component.html',
  styleUrls: ['./project-delete.component.scss']
})
export class ProjectDeleteComponent implements OnInit {

  currentProject: Project;
  constructor(
    private projectService: ProjectService
  ) {
    this.projectService.currentProject.subscribe(project => {
      this.currentProject = project;
    });
  }

  ngOnInit(): void {
  }

  deleteProject() {
    this.projectService.delete(this.currentProject.id).subscribe(result => { });
  }
}
