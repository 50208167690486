import { Component,  Inject, OnInit,} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectService } from '../project.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-project-report-details',
  templateUrl: './project-report-details.component.html',
  styleUrls: ['./project-report-details.component.scss']
})
export class ProjectReportDetailsComponent implements OnInit {
  location: string;
  donors: [];
  sum : Object;
  report: Object;
  reportType: string;
  project: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private projectService: ProjectService,
  ) { 
    this.location = data.location;
    this.donors = data.donors;
    this.sum = data.sum;
    this.report = data.report;
    this.reportType = data.reportType;
    this.project = data.project
  }

  getFundingSum(donor: string, year:string, type: string){
    return  this.report?.[year]?.[this.location]?.[donor]?.[type] ?? 0;
  }

  dataKeys(data) {
    return Object.keys(data);
  }

  ngOnInit(): void {}

  exportReport(){
    if(this.reportType==='reportbylocation'){
      this.projectService.exportReportByLocatioin(this.project,this.location).subscribe( data => {
        saveAs(data, `report${this.location}.xlsx`)
      }) 
    } else {
      this.projectService.exportReportByDonor(this.project,this.location).subscribe( data => {
        saveAs(data, `report${this.location}.xlsx`)
      }) 
    }
    
  }
}

