import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeneficiaryService } from '../beneficiaries.service';

@Component({
  selector: 'app-beneficiaries-delete',
  templateUrl: './beneficiaries-delete.component.html',
  styleUrls: ['./beneficiaries-delete.component.scss']
})
export class BeneficiariesDeleteComponent {

  beneficiary;

  constructor(
    private snackBar: MatSnackBar,
    private beneficiaryService: BeneficiaryService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.beneficiary = data;
  }

  deleteBeneficiary() {
    this.beneficiaryService.delete(this.beneficiary.value.id).subscribe((r) => {
      this.snackBar.open('Beneficiaary deleted successfully', 'delete', { duration: 2000 });
      return true;
    });
  }
}
