import { FormControl, Validators } from '@angular/forms';

import { Funding } from './funding.model';

export class FundingForm {
  id = new FormControl()
  donor = new FormControl(null, Validators.required)
  intervention_year_location = new FormControl()
  sum = new FormControl(null, Validators.required)
  type = new FormControl(null, Validators.required)

  constructor(funding: Funding) {
    this.id.setValue(funding.id || null)
    this.donor.setValue(funding.donor)
    this.intervention_year_location.setValue(funding.intervention_year_location)
    this.sum.setValue(funding.sum)
    this.type.setValue(funding.type)
  }
}
