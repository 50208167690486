import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Namevalue } from './namevalue.model';


@Injectable({
  providedIn: 'root'
})
export class NamevalueService {

  baseUrl = '/api/namevalues/';

  constructor(private http: HttpClient) { }

  list(): Observable<Namevalue[]> {
    return this.http.get<Namevalue[]>(this.baseUrl);
  }

  getByIdInterventionYearLocation(interventionId: number): Observable<Namevalue> {
    return this.http.get<Namevalue>(this.baseUrl + '?intervention_year_location=' + interventionId);
  }

  create(nv: Namevalue): Observable<Namevalue> {
    return this.http.post<Namevalue>(this.baseUrl, nv);
  }

  update(nv: Namevalue): Observable<Namevalue> {
    return this.http.patch<Namevalue>(this.baseUrl + nv.id + '/', nv);
  }

  delete(nvID: number) {
    return this.http.delete(this.baseUrl + nvID + '/');
  }
}
