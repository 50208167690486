import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {NamevaluePair} from './namevalue.model'
@Injectable({
  providedIn: 'root'
})
export class NamevaluePairService {

  baseUrl = '/api/namevaluepairs/';

  constructor(private http: HttpClient) { }

  list(): Observable<NamevaluePair[]> {
    return this.http.get<NamevaluePair[]>(this.baseUrl);
  }

  delete(id: number){
    return this.http.delete(this.baseUrl+id+'/');
  }

}
