<app-header></app-header>

<mat-card>

  <ng-container>
    <form [formGroup]="interventionDetailsForm">
      <div style="display: flex; margin-top: 50px;">
        <mat-form-field appearance="outline" class="intervention_name">
          <mat-label>Intervention name</mat-label>
          <input matInput placeholder="Intervention name" formControlName="name" md-clear-button="true" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Type of Intervention</mat-label>
          <mat-select formControlName="intervention_type">
            <mat-option *ngFor="let option of interventionTypes" [value]="option.id" [disabled]="!option.is_active"
              (onSelectionChange)="onTypeChange($event, option)">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Specific Intervention</mat-label>
          <mat-select formControlName="spec_type" required>
            <mat-option *ngFor="let option of specTypes" [value]="option.id" [disabled]="!option.is_active"
              (onSelectionChange)="onSubTypeChange($event, option)">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>SDG</mat-label>
          <mat-select formControlName="sdg" required>
            <mat-option *ngFor="let option of sdgs" [value]="option.id" [disabled]="!option.is_active">
                <img src="/assets/images/sdg_{{option.id}}.png" style="width:20px" />
                {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Signature Solution</mat-label>
          <mat-select formControlName="signature_solution" required>
            <mat-option *ngFor="let option of solutions" [value]="option.id" [disabled]="!option.is_active">
              <img src="/assets/images/sig_sol_{{option.id}}.jpg" style="width:20px" />
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Comment</mat-label>
          <textarea matInput placeholder="..." formControlName="comment"></textarea>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipGrid aria-label="Tag selection">
            <mat-chip color="primary" *ngFor="let tag of tags" (removed)="remove(tag)">
              {{tag.name}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Select..." #tagInput [formControl]="tagCtrl" matInput
            [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.id" [disabled]='!tag.is_active'>
              {{tag.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

     
      <div style="display:flex; width:200px; align-items: center;background-color: #f2f2f2; padding-left:5px; padding-right:5px; padding-top: 5px;">
        
          <!-- <mat-label style="font-size:small; opacity:'0.7'">Select Year</mat-label> -->
          <div class="select-year">
            <mat-select (selectionChange)="yearChangeSelect($event)" [value]="years.controls[currentIndex]?.value?.year">
              <mat-option *ngFor="let control of years.controls; index as i" [value]="control.value.year">
                {{ control.value.year }}
              </mat-option>
            </mat-select>
          </div>
        
        <button mat-mini-fab (click)="copyYearDialog()" *ngIf="canEdit && (years.length>1)"  class="copy-button" matTooltip="Copy Year">
          <mat-icon>file_copy</mat-icon>
        </button>
      </div>
    
      <app-funding-beneficiaries 
        [years]="years" 
        [yearForm]="years.controls[currentIndex]" 
        [canEdit]="canEdit" 
        [year]="currentIndex"
        (copyLocationEvent)="copyLocation($event)"
        ></app-funding-beneficiaries>

      <div class="actions">

        <button mat-raised-button type="button" color="accent" (click)="onSubmit(false)" *ngIf="canEdit">Save</button>

        <button mat-icon-button (click)="exitDialog()">
          <mat-icon>close</mat-icon>
        </button>

      </div>

    </form>

  </ng-container>

</mat-card>