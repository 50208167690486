import { FormControl, Validators } from '@angular/forms'
import { Beneficiary } from './beneficiaries.model';

export class BeneficiaryForm {
  id = new FormControl()
  intervention_year_location = new FormControl()
  female = new FormControl()
  male = new FormControl()
  total = new FormControl()
  type = new FormControl(null, Validators.required)
  name = new FormControl(null, Validators.required)
  beneficiary_category = new FormControl(null, Validators.required)
  comment = new FormControl()

  constructor(beneficiary?: Beneficiary) {
    this.id.setValue(beneficiary?.id || null)
    this.intervention_year_location.setValue(beneficiary?.intervention_year_location)
    this.female.setValue(beneficiary?.female || null)
    this.male.setValue(beneficiary?.male || null)
    this.total.setValue(beneficiary?.total)
    this.type.setValue(beneficiary?.type)
    this.name.setValue(beneficiary?.name)
    this.type.setValue(beneficiary?.type)
    this.beneficiary_category.setValue(beneficiary?.beneficiary_category)
    this.comment.setValue(beneficiary?.comment || null)
  }
}
