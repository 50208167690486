import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';

import { FundingModule } from '../funding/funding.module';
import { BeneficiariesModule } from '../beneficiaries/beneficiaries.module'
import { FundingBeneficiariesComponent } from '../funding-beneficiaries/funding-beneficiaries.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import { NamevalueModule } from '../namevalue/namevalue.module';

@NgModule({
  declarations: [
    FundingBeneficiariesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule,
    FundingModule,
    BeneficiariesModule,
    NamevalueModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule
  ],
  exports: [
    FundingBeneficiariesComponent
  ]
})
export class FundingBeneficiariesModule { }
