import { Component, Inject, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LocationFlatNode } from './locations.model';
import { LocationService } from './locations.service';
import { Intervention } from '../intervention/intervention.model';

import { LocationDeleteComponent } from './location-delete/location-delete.component';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  intervention: Intervention;
  yearLocations;

  @Output() addLocationEvent = new EventEmitter<LocationFlatNode>();
  @Output() removeLocationEvent = new EventEmitter<LocationFlatNode>();
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;

  constructor(
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<LocationsComponent>,
    public dialog: MatDialog,
  ) {
    this.yearLocations = data;
  }

  locationSelected: LocationFlatNode[] = [];
  locations: LocationFlatNode[] = [];
  locationsList: LocationFlatNode[] = []
  locationCtrl = new FormControl();
  autocompleteFilteredLocations: Observable<LocationFlatNode[]>;

  ngOnInit() {
    this.locationService.listFlat().subscribe((data) => {
      this.locations = data;
     
      this.autocompleteFilteredLocations = this.locationCtrl.valueChanges
            .pipe(
              startWith(''),
              map(donor => donor ? this._filterLocations(donor) : this.locationsList.slice())
            );

      this.locationSelected = [];
      for (const locCtrl of this.yearLocations) {
        if (this.locationSelected.filter(node => node.id == locCtrl.location.id).length == 0) {
          this.locationSelected.push(this.locations.find(node => node.id == locCtrl.location.id));
        }
      }
      this.locationSelected.sort((a,b)=> a.name.localeCompare(b.name));
      this.locationsList = data.filter((location) => !this.locationSelected.includes(location));
    });

  }
  private _filterLocations(value: string): LocationFlatNode[] {
    const filterValue = value.toLowerCase();
    return this.locationsList.filter(state => (state.name.toLowerCase().indexOf(filterValue) === 0));
  }

  userLocationRemove(node: LocationFlatNode): void {
    const index = this.locationSelected.indexOf(node);
    if (index === -1) { return; }

    const dialog2 = this.dialog.open(LocationDeleteComponent);

    dialog2.afterClosed().subscribe(result => {
      if (result === true) {
        this.locationSelected.splice(index, 1);
        this.removeLocationEvent.emit(node);
       }
    });

  }

  onSubmit() {
    this.dialogRef.close();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.locationSelected.findIndex(l => l.name === event.option.viewValue);
    if (index === -1) {
      const selectedL = this.locationsList.find(l => l.name === event.option.viewValue);
      this.locationSelected.push(selectedL);
      this.locationSelected.sort((a,b)=> a.name.localeCompare(b.name));
      this.addLocationEvent.emit(selectedL);
      this.locationsList = this.locationsList.filter(l => l.name !== selectedL.name);
    }
    this.locationInput.nativeElement.value = '';
    this.locationCtrl.setValue('');
    this.locationCtrl.disable();
    this.locationCtrl.enable();
  }

}
