import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeneficiaryService } from 'src/app/beneficiaries/beneficiaries.service';
import { FundingService } from 'src/app/funding/funding.service';

import { Intervention } from '../intervention.model';
import { InterventionService, YearLocationService } from '../intervention.service';
import { NamevalueService } from 'src/app/namevalue/namevalue.service';

@Component({
  selector: 'app-intervention-copy',
  templateUrl: './intervention-copy.component.html',
  styleUrls: ['./intervention-copy.component.scss']
})
export class InterventionCopyComponent {

  intervention: Intervention;

  constructor(
    private snackBar: MatSnackBar,
    private interventionService: InterventionService,
    private yearLocationService: YearLocationService,
    private beneficiaryService: BeneficiaryService,
    private fundingService: FundingService,
    private namevalueSerice: NamevalueService,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<InterventionCopyComponent>,
  ) {
    this.intervention = data;
  }
  async copyIntervention(): Promise<boolean> {
    try {
      for( let years of this.intervention.years){
        for(let loc of years.locations){
          loc.tags_id = loc.tags.map(t => t.id);
        }
      }
      const tags_id = this.intervention.tags.map(t => t.id);
      this.intervention.tags = undefined;
      let result = await this.interventionService.create({ ...this.intervention, name: this.intervention.name + '-copy#', id: null, tags_id:[]} ).toPromise();
      result = await this.interventionService.update({...result, tags_id}).toPromise();
      
      for (let [index, year] of this.intervention.years.entries()) { 
        for (let yearLocation of year.locations) {
          let yearLocationNew = await this.yearLocationService.create({ ...yearLocation, intervention_year: result.years.filter( y => y.year===year.year)[0].id, fundings: [], beneficiaries: [], namevaluelocations: [] }).toPromise()
          for (let beneficiary of yearLocation.beneficiaries) {
            await this.beneficiaryService.create({ ...beneficiary, intervention_year_location: yearLocationNew.id }).toPromise()
          }
          for (let funding of yearLocation.fundings) {
            await this.fundingService.create({ ...funding, intervention_year_location: yearLocationNew.id }).toPromise()
          }
          for (let namevalue of yearLocation.namevaluelocations) {
            await this.namevalueSerice.create({ ...namevalue, intervention_year_location: yearLocationNew.id }).toPromise()
          }
        }
      }
      this.snackBar.open('Copied successfully!', 'copy', {
        duration: 2000,
      });
      this.dialogRef.close(true)
      return true
    } catch {
      this.snackBar.open('Failed to copy intervention!', 'copy', {
        duration: 2000,
      });
      this.dialogRef.close(false)
      return false
    }
  }
}
